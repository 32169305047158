import { useAuth0 } from "@auth0/auth0-react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/material";
import { FileLink, UploadInput } from "atoms";
import { FormContainer } from "components";
import {
    actionsData,
    fileData,
    importReleaseClientLinksFormData,
    permissionsData,
} from "data";
import { uploadFileMutations } from "graph";
import { useUploadFile } from "hooks";
import { PropTypes } from "prop-types";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useImportReleaseClientLinksSchema } from "schemas";
import {
    canDo,
    deleteFileHandler,
    renderFormControllerHandler,
    setUploadInputPreviousValueHandler,
} from "utils";

const ImportReleaseClientLinksForm = ({
    setLinksData,
    setModalData,
}) => {
    const { user } = useAuth0();

    const { t } = useTranslation();

    const schema = useImportReleaseClientLinksSchema();

    const formMethods = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const {
        control,
        formState: { errors },
        setError,
        setValue,
        watch,
    } = formMethods;

    const {
        filesData,
        filesLoading,
        setFilesData,
        uploadFile,
    } = useUploadFile();

    const {
        mediaCreate: mediaCreatePermission,
        mediaRead: mediaReadPermission,
    } = permissionsData.permissions.keys;

    const allowedPermissions = [mediaCreatePermission, mediaReadPermission];

    const renderFormControllerChildrenHandler = (
        field,
        label,
        name,
    ) => (
        <Box mb={1}>
            <UploadInput
                accept={fileData.acceptedExtensions.csv}
                color={errors[name]?.message ? "error" : "primary"}
                error={errors[name]?.message}
                hasError={!!errors[name]?.message}
                label={t(`labels.${label}`)}
                loading={filesLoading?.[name]}
                disabled={!canDo(
                    user,
                    allowedPermissions,
                ) || filesLoading?.[name]}
                onChange={(e) => {
                    field.onChange(e.target.value);

                    setFilesData({ [name]: e.target.files[0] });
                }}
            />
            {(filesData?.[name] && watch(name)) && (
                <FileLink
                    name={filesData?.[name]?.name}
                    deleteAction={() => deleteFileHandler(
                        setFilesData,
                        formMethods,
                        name,
                    )}
                    hasDelete
                />
            )}
        </Box>
    );

    useEffect(
        () => {
            setLinksData(filesData);
        },
        [filesData], // eslint-disable-line
    );

    useEffect(
        () => {
            setUploadInputPreviousValueHandler(
                watch,
                setError,
                setValue,
                filesData,
                fileData.names.releaseClientLinks,
            );
        },
        [watch(fileData.names.releaseClientLinks)], // eslint-disable-line
    );

    return (
        <FormContainer
            action={actionsData["import"]}
            formMethods={formMethods}
            loading={filesLoading?.releaseClientLinksFile}
            setModalData={setModalData}
            data={renderFormControllerHandler(
                control,
                importReleaseClientLinksFormData,
                renderFormControllerChildrenHandler,
            )}
            hasCancelButton
            withoutShadow
            onSubmitForm={() => uploadFile(
                filesData?.releaseClientLinksFile,
                uploadFileMutations.releaseClientLinks,
                null,
                fileData.names.releaseClientLinks,
            )}
        />
    );
};

export default ImportReleaseClientLinksForm;

ImportReleaseClientLinksForm.propTypes = {
    setLinksData: PropTypes.func,
    setModalData: PropTypes.func,
};
