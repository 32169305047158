import { useAuth0 } from "@auth0/auth0-react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/material";
import {
    AutoCompleteMenu,
    FileLink,
    Loader,
    UploadInput,
} from "atoms";
import { FormContainer } from "components";
import {
    actionsData,
    fileData,
    identifiersData,
    inputData,
    permissionsData,
    selectionsData,
    trackFormData,
} from "data";
import { uploadFileMutations } from "graph";
import { useArtistsList, useUploadFile } from "hooks";
import { PropTypes } from "prop-types";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useTrackSchema } from "schemas";
import { StyledTextField } from "styles";
import {
    canDo,
    deleteFileHandler,
    renderFormControllerHandler,
    setUploadInputPreviousValueHandler,
} from "utils";

const TrackForm = ({
    action,
    hasDrawer,
    loading,
    onSubmitForm,
    values,
}) => {
    const { t } = useTranslation();

    const { user } = useAuth0();

    const selections = useSelector((state) => state.selectionsReducer);

    const {
        filesData,
        filesLoading,
        setFilesData,
        uploadFile,
    } = useUploadFile();

    const {
        count: artistsCount,
        fetched: artistsFetched,
        loading: artistsLoading,
        page: artistsPage,
        refetch: artistsRefetch,
        searchKey: artistsSearchKey,
    } = useArtistsList(
        true,
        true,
        {},
        null,
    );

    const schema = useTrackSchema();

    const formMethods = useForm({
        defaultValues: values,
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const {
        control,
        formState: { errors },
        setError,
        setValue,
        watch,
    } = formMethods;

    const {
        mediaCreate: mediaCreatePermission,
        mediaRead: mediaReadPermission,
    } = permissionsData.permissions.keys;

    const allowedPermissions = [mediaCreatePermission, mediaReadPermission];

    const {
        oldImage: oldImageInputName,
        oldTrack: oldTrackInputName,
        primaryArtist: primaryArtistInputName,
    } = inputData.names;

    const {
        autoComplete: autoCompleteInputType,
        file: fileInputType,
        multiAutoComplete: multiAutoCompleteInputType,
        text: textInputType,
        textArea: textAreaInputType,
    } = inputData.types;

    const asyncMenus = {
        artists: {
            count: artistsCount,
            loading: artistsLoading,
            page: artistsPage,
            refetch: artistsRefetch,
            searchKey: artistsSearchKey,
            selectionKey: selectionsData.keys.artists,
        },
    };

    const renderFormControllerChildrenHandler = (
        field,
        label,
        name,
        type,
        isOptional,
        selectionKey,
        isAsyncMenu,
    ) => {
        switch (type) {
        case textInputType:
        case textAreaInputType:
            return (
                <StyledTextField
                    {...field}
                    error={errors[name]}
                    helperText={errors?.[name]?.message}
                    id={`${name}_${identifiersData.ids.input}`}
                    label={isOptional ? `${t(`labels.${label}`)} (${t("labels.optional")})` : t(`labels.${label}`)}
                    minRows={type === textAreaInputType ? 4 : 1}
                    multiline={type === textAreaInputType}
                    value={field.value || ""}
                    variant="outlined"
                    fullWidth
                />
            );
        case fileInputType:
            const file = name === fileData.names.image ? oldImageInputName : oldTrackInputName; //eslint-disable-line

            return (
                <Box mb={1}>
                    <UploadInput
                        accept={name === fileData.names.image ? fileData.acceptedExtensions.image : fileData.acceptedExtensions.audio}
                        color={errors[name]?.message ? "error" : "primary"}
                        error={errors[name]?.message}
                        field={field}
                        hasError={!!errors[name]?.message}
                        id={name}
                        label={t(`labels.${label}`)}
                        loading={filesLoading?.[name]}
                        disabled={!canDo(
                            user,
                            allowedPermissions,
                        )}
                        onChange={(e) => {
                            uploadFile(
                                e.target.files[0],
                                name === fileData.names.image ? uploadFileMutations.image : uploadFileMutations.track,
                                formMethods,
                                name,
                            );
                        }}
                    />
                    {filesData?.[name] && (
                        <FileLink
                            id={name}
                            name={filesData?.[name]?.name}
                            deleteAction={() => deleteFileHandler(
                                setFilesData,
                                formMethods,
                                name,
                            )}
                            hasDelete
                        />
                    )}
                    {((values?.oldImage || values?.oldTrack) && !filesData?.[name]) && (
                        <FileLink
                            hasDownload={!!values?.[file]}
                            hasImage={!!(name === fileData.names.image && values?.oldImage?.downloadThumbnail)}
                            id={name}
                            link={name === fileData.names.image ? values?.[file]?.downloadThumbnail : values?.[file]}
                            name={name === fileData.names.image ? values?.[file]?.downloadThumbnail : values?.[file]}
                            hasJustifyStart
                        />
                    )}
                </Box>
            );
        case multiAutoCompleteInputType:
            return (
                <AutoCompleteMenu
                    asyncMenuData={asyncMenus?.[selectionKey]}
                    defaultValue={values ? values[`${name}Array`] : []}
                    disabledOption={watch(primaryArtistInputName)}
                    errors={errors}
                    getRenderedOption={(option) => option?.label}
                    isAsyncMenu={isAsyncMenu}
                    isOptional={isOptional}
                    label={label}
                    name={name}
                    optionLabel={(option) => option.label}
                    options={selections?.[selectionKey]}
                    isMultiple
                    onChange={(_, options) => {
                        if (options) field.onChange(options);
                        else field.onChange([]);
                    }}
                />
            );
        case autoCompleteInputType:
            return (
                <AutoCompleteMenu
                    asyncMenuData={asyncMenus?.[selectionKey]}
                    defaultValue={selections?.[selectionKey]?.find((option) => option.value === values?.primaryArtist)}
                    errors={errors}
                    getRenderedOption={(option) => option?.label}
                    isAsyncMenu={isAsyncMenu}
                    label={label}
                    name={name}
                    optionLabel={(option) => option.label}
                    options={selections?.[selectionKey]}
                    onChange={(_, option) => {
                        if (option) field.onChange(option.value);
                        else field.onChange("");
                    }}
                />
            );
        default: return "";
        }
    };

    useEffect(
        () => {
            setUploadInputPreviousValueHandler(
                watch,
                setError,
                setValue,
                filesData,
                fileData.names.track,
            );
        },
        [watch(fileData.names.track)], // eslint-disable-line
    );

    useEffect(
        () => {
            setUploadInputPreviousValueHandler(
                watch,
                setError,
                setValue,
                filesData,
                fileData.names.image,
            );
        },
        [watch(fileData.names.image)], // eslint-disable-line
    );

    if (artistsLoading && !artistsFetched) return <Loader />;

    return (
        <FormContainer
            action={action}
            disabled={filesLoading?.imageFile || filesLoading?.trackFile}
            filesData={[fileData.names.image, fileData.names.track]}
            formMethods={formMethods}
            hasClearAfterSubmission={action === actionsData.create}
            loading={loading}
            setFilesData={setFilesData}
            data={renderFormControllerHandler(
                control,
                trackFormData,
                renderFormControllerChildrenHandler,
                hasDrawer,
            )}
            hasSubmitButton={canDo(
                user,
                allowedPermissions,
            )}
            withoutShadow
            onSubmitForm={onSubmitForm}
        />
    );
};

export default TrackForm;

TrackForm.propTypes = {
    action: PropTypes.string,
    hasDrawer: PropTypes.bool,
    loading: PropTypes.bool,
    onSubmitForm: PropTypes.func,
    resetForm: PropTypes.number,
    values: PropTypes.object,
};
