import styled from "@emotion/styled";
import { Autocomplete } from "@mui/material";
import { identifiersData, inputData } from "data";

const {
    classNames: {
        searchButton: searchButtonClassName,
        searchSelectMenu: searchSelectMenuClassName,
        searchTagsBox: searchTagsBoxClassName,
        searchTextField: searchTextFieldClassName,
    },
    ids: {
        label: labelId,
        selectMenu: selectMenuId,
    },
    props: { fromNavbar: fromNavbarProp },
} = identifiersData;

const StyledSearchInput = styled(
    Autocomplete,
    { shouldForwardProp: (prop) => prop !== fromNavbarProp },
)(({
    fromNavbar,
    theme,
}) => ({
    [`& #${inputData.names.search}_${selectMenuId}_${labelId}`]: {
        cursor: "pointer",
        top: 20,
        zIndex: "-1",
    },
    "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input": { minWidth: 120 },
    "& .MuiInputBase-root": { padding: 0 },
    [`& .MuiInputBase-root:not(.${searchSelectMenuClassName})`]: {
        [`& .${searchButtonClassName}`]: {
            "@media(max-width: 700px)": { display: "none" },
            alignItems: "center",
            background: theme.palette?.primary.main,
            borderRadius: "0 45px 45px 0",
            color: theme.palette?.grey[100],
            display: "flex",
            right: 2,
        },
        "@media(max-width: 700px)": { borderRadius: "0!important" },
        borderRadius: "45px !important",
        minHeight: 40,
        minWidth: 200,
    },
    "& .MuiSelect-select": {
        fontSize: 12,
        paddingRight: "18px !important",
    },
    [`& .${searchSelectMenuClassName}`]: {
        "&.MuiOutlinedInput-root": { paddingRight: 8 },
        "@media(max-width: 500px)": {
            borderRadius: "10px 0 10px 0",
            width: "100%",
        },
        borderRadius: "10px 0 0 10px",
        height: 40,
        width: 122,
    },
    [`& .${identifiersData.classNames.searchSelectMenuFormControl}`]: {
        "& fieldset": {
            border: 0,
            borderRight: `1px solid ${theme.palette?.grey[400]}!important`,
        },
    },
    [`& .${searchTagsBoxClassName}`]: {
        "&::-webkit-scrollbar": { display: "none" },
        "@media(max-width: 825px)": {
            maxWidth: 180,
        },
        maxWidth: 300,
        scrollbarWidth: "none",
    },
    [`& .${searchTextFieldClassName}`]: {
        "& > div.MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
            "& > div.MuiAutocomplete-endAdornment": {
                order: 2,
                position: "relative",
            },
            "& button": { order: 3 },
            paddingRight: 9,
        },
    },
    "@media(max-width: 700px)": {
        "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input": { minWidth: 80 },
    },
    "@media(min-width: 700px)": {
        display: fromNavbar ? "block" : "none",
        minWidth: 500,
    },
    display: fromNavbar ? "none" : "block",
    transition: "ease-in-out .2s !important",
}));

export default StyledSearchInput;
