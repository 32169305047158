import { useAuth0 } from "@auth0/auth0-react";
import { Loader } from "atoms";
import { permissionsData, urlsData } from "data";
import { PropTypes } from "prop-types";
import { Navigate, useLocation } from "react-router-dom";
import { canDo, hasRole } from "utils";
import { ForbiddenView } from "views";

const ProtectedRoute = ({
    component: Component,
    oneOfPermissions,
    permissions = [],
    roles = [],
}) => {
    const location = useLocation();

    const {
        isAuthenticated,
        isLoading,
        user,
    } = useAuth0();

    const {
        driveManagement: { url: driveManagementRouteUrl },
        home: homeRouteUrl,
        login: loginRouteUrl,
        revenueReports: { url: revenueReportsRouteUrl },
    } = urlsData.routes;

    const mediaReadPermission = user?.[permissionsData.key]?.find((permission) => permission === permissionsData.permissions.keys.mediaRead);

    const driveReadPermission = user?.[permissionsData.key]?.find((permission) => permission === permissionsData.permissions.keys.driveRead);

    const reportReadPermission = user?.[permissionsData.key]?.find((permission) => permission === permissionsData.permissions.keys.reportRead);

    const protectedRouteUI = () => {
        if (isLoading) return <Loader />;

        if (isAuthenticated) {
            if (canDo(
                user,
                permissions,
                oneOfPermissions,
            ) || hasRole(
                user,
                roles,
            )) return <Component />;

            if (!mediaReadPermission && location.pathname === homeRouteUrl) {
                if (reportReadPermission) return <Navigate to={{ pathname: revenueReportsRouteUrl }} />;

                if (driveReadPermission) return <Navigate to={{ pathname: driveManagementRouteUrl }} />;
            }

            return <ForbiddenView />;
        }

        return <Navigate to={{ pathname: loginRouteUrl }} />;
    };

    return protectedRouteUI();
};

export default ProtectedRoute;

ProtectedRoute.propTypes = {
    component: PropTypes.object,
    oneOfPermissions: PropTypes.bool,
    permissions: PropTypes.array,
    roles: PropTypes.array,
};
