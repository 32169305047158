/* eslint-disable max-lines */
import { fileData, importerConfigData, selectionsData } from "data";
import {
    useAddReleaseTrack,
    useArtistsList,
    useCountriesSelectionList,
    useCreateArtist,
    useCreateRelease,
    useCreateReleaseDocument,
    useCreateReleaseNote,
    useCreateReleaseOwnership,
    useCreateTrack,
    useDeleteRelease,
    useDocumentTypesSelectionList,
    useGenresSelectionList,
    useReleaseTypesSelectionList,
    useRightTypesSelectionList,
    useTerritoriesList,
    useTracksList,
    useUploadFile,
    useVideoTypesSelectionList,
} from "hooks";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setCompletedImporterRelease, setInvalidImporterRelease, stopReleaseImporting } from "redux/actions";
import { theme } from "theme";
import {
    addExistingTrackHandler,
    checkArtistExistenceHandler,
    checkSecondaryArtistExistence,
    createDocumentHandler,
    createNonExistingTrackHandler,
    createReleaseHandler,
    createReleaseNoteHandler,
    createReleaseOwnershipHandler,
    getExistingAndNonExistingTracksHandler,
    searchArtistsHandler,
    startLoadingArtistHandler,
    uploadDocumentFileHandler,
    uploadNonExistingTrackFileHandler,
    uploadReleaseImageHandler,
    uploadVideoFileHandler,
    validateImporterDataHandler,
} from "utils";

const useImportRelease = () => {
    const initialState = {
        artist: {
            loading: false,
            searching: false,
            success: null,
        },
        documents: {
            loading: false,
            success: null,
        },
        release: {
            createReleaseSuccess: null,
            deletingReleaseLoading: false,
            loading: false,
            noteCreated: null,
            ownershipCreated: null,
            success: null,
        },
        releaseImage: {
            loading: false,
            success: null,
        },
        secondaryArtists: {
            loading: false,
            searching: false,
            success: null,
        },
        tracks: {
            loading: false,
            searching: false,
            success: null,
        },
        videos: {
            loading: false,
            success: null,
        },
    };

    const [entries, setEntries] = useState({
        documentsTypes: [],
        genre: {},
        nationality: {},
        releaseType: {},
        rightType: {},
        secondaryArtistsNationalities: [],
        territory: {},
        videosTypes: [],
    });

    const [status, setStatus] = useState(initialState);

    const [importing, setImporting] = useState(false);

    const [validation, setValidation] = useState(null);

    const [currentStep, setCurrentStep] = useState(null);

    const [data, setData] = useState({});

    const [artistId, setArtistId] = useState(null);

    const [existingSecondaryArtists, setExistingSecondaryArtists] = useState([]);

    const [nonExistingSecondaryArtists, setNonExistingSecondaryArtists] = useState([]);

    const [existingTracks, setExistingTracks] = useState([]);

    const [nonExistingTracks, setNonExistingTracks] = useState([]);

    const [uploadedTracksFiles, setUploadedTracksFiles] = useState([]);

    const [addedExistingTracks, setAddedExistingTracks] = useState([]);

    const [createdNonExistingTracks, setCreatedNonExistingTracks] = useState([]);

    const [createdVideos, setCreatedVideos] = useState([]);

    const [uploadedDocumentsFiles, setUploadedDocumentsFiles] = useState([]);

    const [createdDocuments, setCreatedDocuments] = useState([]);

    const [artistsNames, setArtistsNames] = useState([]);

    const [artistsCodes, setArtistsCodes] = useState([]);

    const [tracksIsrcs, setTracksIsrcs] = useState([]);

    const [artists, setArtists] = useState([]);

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const selections = useSelector((state) => state.selectionsReducer);

    const stopImporting = useSelector((state) => state.releasesReducer).importer?.stopImporting;

    const formMethods = useForm({});

    const { getValues } = formMethods;

    const {
        countries,
        documentTypes,
        genres,
        releaseTypes,
        rightTypes,
        territories,
        videoTypes,
    } = selectionsData.keys;

    useCountriesSelectionList();

    useGenresSelectionList();

    useReleaseTypesSelectionList();

    useRightTypesSelectionList();

    useVideoTypesSelectionList();

    useDocumentTypesSelectionList();

    useTerritoriesList(
        false,
        false,
    );

    const {
        cancelRequest: cancelArtistsListRequest,
        data: artistsData,
        fetched: fetchedArtists,
        setFetched: setFetchedArtists,
    } = useArtistsList(
        false,
        false,
        null,
        {
            codesList: artistsCodes,
            namesList: artistsNames,
            originalData: data,
            release: data?.release,
            setCurrentStep,
            setInvalidImporterRelease,
            stopping: stopImporting.finish,
        },
    );

    const {
        cancelRequest: cancelTracksListRequest,
        data: tracksData,
        fetched: fetchedTracks,
        setFetched: setFetchedTracks,
    } = useTracksList(
        false,
        false,
        {},
        {
            isrcsList: tracksIsrcs,
            originalData: data,
            release: data?.release,
            setCurrentStep,
            setInvalidImporterRelease,
            stopping: stopImporting.finish,
        },
    );

    const {
        cancelRequest: cancelCreateArtistRequest,
        createArtist,
    } = useCreateArtist(
        true,
        true,
    );

    const {
        cancelRequest: cancelCreateReleaseRequest,
        createRelease,
        data: releaseData,
    } = useCreateRelease(
        true,
        true,
    );

    const {
        deleteRelease,
        loading: deleteReleaseLoading,
    } = useDeleteRelease(
        false,
        null,
        null,
        null,
        false,
        null,
        false,
        true,
        true,
    );

    const {
        cancelRequest: cancelCreateReleaseOwnershipRequest,
        createReleaseOwnership,
    } = useCreateReleaseOwnership(
        true,
        true,
    );

    const {
        cancelRequest: cancelCreateReleaseNoteRequest,
        createReleaseNote,
    } = useCreateReleaseNote(
        true,
        true,
    );

    const {
        addReleaseTrack,
        cancelRequest: cancelAddTrackToReleaseRequest,
    } = useAddReleaseTrack(
        null,
        true,
        true,
    );

    const {
        cancelRequest: cancelCreateTrackRequest,
        createTrack,
    } = useCreateTrack(
        true,
        true,
    );

    const {
        cancelRequest: cancelCreateReleaseDocumentRequest,
        createReleaseDocument,
    } = useCreateReleaseDocument(
        null,
        true,
        true,
    );

    const {
        cancelCreateImageRequest,
        cancelCreateVideoRequest,
        cancelRequest: cancelUploadFileRequest,
        uploadFile,
    } = useUploadFile(
        null,
        null,
        true,
        true,
    );

    const resetImportReleaseStateHandler = () => {
        setArtistId(null);

        setExistingSecondaryArtists([]);

        setNonExistingSecondaryArtists([]);

        setExistingTracks([]);

        setNonExistingTracks([]);

        setUploadedTracksFiles([]);

        setAddedExistingTracks([]);

        setCreatedNonExistingTracks([]);

        setCreatedVideos([]);

        setUploadedDocumentsFiles([]);

        setCreatedDocuments([]);

        dispatch(stopReleaseImporting({
            finish: false,
            start: false,
        }));

        setCurrentStep(null);

        startLoadingArtistHandler({ setStatus });
    };

    const importRelease = ({
        artist,
        documents,
        release,
        tracks,
    }) => {
        const secondaryArtists = [];

        let videos = [];

        tracks?.forEach(({ secondaryArtist }) => secondaryArtist && secondaryArtists.push({ ...secondaryArtist }));

        tracks?.forEach(({ videos: trackVideos }) => {
            if (trackVideos.length > 0) videos = videos.concat(trackVideos);
        });

        const importerData = {
            artist,
            documents,
            release,
            secondaryArtists,
            tracks,
            videos,
        };

        searchArtistsHandler({
            artist,
            secondaryArtists,
            setArtistsCodes,
            setArtistsNames,
        });

        setData(importerData);
    };

    // This useEffect is for debugging logs ONLY in the deployed Environments to check the updates of some variables, and not related to any code logic
    useEffect(
        () => {
            if (window?.__RUNTIME_CONFIG__?.ENABLE_DEBUG_LOGS === "true") { // eslint-disable-line
                console.log(
                    "%c *********************************************",
                    `color: ${theme.palette.primary.main}`,
                );

                console.log(
                    "status",
                    status,
                );

                console.log(
                    "currentStep",
                    currentStep,
                );

                console.log(
                    "releaseData",
                    {
                        ...data?.release,
                        id: releaseData?.createOneRelease?.id,
                    },
                );

                console.log(
                    "existingSecondaryArtists",
                    existingSecondaryArtists,
                );

                console.log(
                    "nonExistingSecondaryArtists",
                    nonExistingSecondaryArtists,
                );

                console.log(
                    "existingTracks",
                    existingTracks,
                );

                console.log(
                    "nonExistingTracks",
                    nonExistingTracks,
                );

                console.log(
                    "uploadedTracksFiles",
                    uploadedTracksFiles,
                );

                console.log(
                    "addedExistingTracks",
                    addedExistingTracks,
                );

                console.log(
                    "createdNonExistingTracks",
                    createdNonExistingTracks,
                );

                console.log(
                    "createdVideos",
                    createdVideos,
                );

                console.log(
                    "uploadedDocumentsFiles",
                    uploadedDocumentsFiles,
                );

                console.log(
                    "createdDocuments",
                    createdDocuments,
                );

                console.log(
                    "%c *********************************************",
                    `color: ${theme.palette.primary.main}`,
                );
            }
        },
        [ // eslint-disable-line
            status,
            currentStep,
            releaseData,
            existingSecondaryArtists,
            nonExistingSecondaryArtists,
            existingTracks,
            nonExistingTracks,
            uploadedTracksFiles,
            addedExistingTracks,
            createdNonExistingTracks,
            createdVideos,
            uploadedDocumentsFiles,
            createdDocuments,
        ],
    );

    useEffect(
        () => {
            if (!importing) {
                setArtistsCodes([]);

                setArtistsNames([]);

                setTracksIsrcs([]);

                setValidation(null);
            }
        },
        [importing],
    );

    useEffect(
        () => {
            if (artistsData && data && fetchedArtists) {
                setFetchedArtists(false);

                setArtistsCodes([]);

                setArtistsNames([]);

                if (validateImporterDataHandler(
                    {
                        ...data,
                        originalData: data,
                        setEntries,
                        setInvalidImporterRelease,
                    },
                    {
                        artists: artistsData,
                        countries: selections[countries],
                        documentTypes: selections[documentTypes],
                        genres: selections[genres],
                        releaseTypes: selections[releaseTypes],
                        rightTypes: selections[rightTypes],
                        territories: selections[territories],
                        videoTypes: selections[videoTypes],
                    },
                    {
                        dispatch,
                        translation: t,
                    },
                )) {
                    resetImportReleaseStateHandler();

                    setArtists(artistsData);

                    setValidation(true);
                } else setValidation(false);
            }
        },
        [artistsData, fetchedArtists, data], // eslint-disable-line
    );

    useEffect(
        () => {
            const checkArtistExistenceSequentially = async () => {
                if (status.artist.searching && Object.entries(data).length > 0) {
                    await checkArtistExistenceHandler(
                        {
                            artist: {
                                ...data?.artist,
                                nationality: entries.nationality,
                            },
                            createArtist,
                            formMethods,
                            originalData: data,
                            release: data?.release,
                            setArtistId,
                            setCurrentStep,
                            setInvalidImporterRelease,
                            setStatus,
                            uploadFile,
                            uploadReleaseImageHandler,
                        },
                        { artists },
                    );
                }
            };

            checkArtistExistenceSequentially();
        },
        [status.artist.searching, entries, data], // eslint-disable-line
    );

    useEffect(
        () => {
            const createReleaseSequentially = async () => {
                if (status.release.loading) {
                    await createReleaseHandler(
                        {
                            artistId,
                            createRelease,
                            imageId: getValues(fileData.names.image),
                            originalData: data,
                            release: {
                                ...data?.release,
                                genre: entries.genre,
                                releaseType: entries.releaseType,
                                rightType: entries.rightType,
                            },
                            setCurrentStep,
                            setInvalidImporterRelease,
                            setStatus,
                        },
                    );
                }
            };

            createReleaseSequentially();
        },
        [status.release.loading, entries], // eslint-disable-line
    );

    useEffect(
        () => {
            const createReleaseOwnershipSequentially = async () => {
                if (releaseData && status.release.createReleaseSuccess) {
                    if (data?.release?.ownership) {
                        await createReleaseOwnershipHandler(
                            {
                                createReleaseOwnership,
                                originalData: data,
                                release: {
                                    ...data?.release,
                                    id: releaseData?.createOneRelease?.id,
                                    ownership: {
                                        ...data?.release?.ownership,
                                        territory: entries.territory,
                                    },
                                },
                                setCurrentStep,
                                setInvalidImporterRelease,
                                setStatus,
                            },
                        );
                    }
                }
            };

            createReleaseOwnershipSequentially();
        },
        [releaseData, status.release.createReleaseSuccess], // eslint-disable-line
    );

    useEffect(
        () => {
            const createReleaseNoteSequentially = async () => {
                if (releaseData && status.release.createReleaseSuccess) {
                    if (!data?.release?.ownership || status.release.ownershipCreated) {
                        if (data?.release?.note) {
                            await createReleaseNoteHandler(
                                {
                                    createReleaseNote,
                                    originalData: data,
                                    release: {
                                        ...data?.release,
                                        id: releaseData?.createOneRelease?.id,
                                    },
                                    setCurrentStep,
                                    setImporting,
                                    setInvalidImporterRelease,
                                    setStatus,
                                },
                            );
                        }
                    }
                }
            };

            createReleaseNoteSequentially();
        },
        [releaseData, status.release.createReleaseSuccess, status.release.ownershipCreated], // eslint-disable-line
    );

    useEffect(
        () => {
            if (
                (status.release.ownershipCreated && status.release.noteCreated)
                || (status.release.ownershipCreated && !data?.release?.note)
                || (!data?.release?.ownership && status.release.noteCreated)
            ) {
                setStatus((prev) => ({
                    ...prev,
                    release: {
                        ...prev.release,
                        loading: false,
                        success: true,
                    },
                    secondaryArtists: {
                        ...prev.secondaryArtists,
                        loading: true,
                        searching: true,
                    },
                }));
            }
        },
        [status.release.ownershipCreated, status.release.noteCreated], // eslint-disable-line
    );

    useEffect(
        () => {
            const checkSecondaryArtistsExistenceSequentially = async () => {
                if (status.secondaryArtists.searching) {
                    if (data?.secondaryArtists?.length > 0) {
                        for (let i = 0; i < data?.secondaryArtists?.length; i++) { // eslint-disable-line
                            const artist = data?.secondaryArtists[i];

                            await checkSecondaryArtistExistence( // eslint-disable-line
                                {
                                    artist: {
                                        ...artist,
                                        nationality: entries.secondaryArtistsNationalities[i],
                                    },
                                    createArtist,
                                    originalData: data,
                                    release: data?.release,
                                    setCurrentStep,
                                    setExistingSecondaryArtists,
                                    setInvalidImporterRelease,
                                    setNonExistingSecondaryArtists,
                                    setStatus,
                                },
                                { artists },
                            );
                        }
                    } else {
                        setStatus((prev) => ({
                            ...prev,
                            secondaryArtists: {
                                ...prev.secondaryArtists,
                                loading: false,
                                searching: false,
                                success: true,
                            },
                            tracks: {
                                ...prev.tracks,
                                loading: true,
                                searching: true,
                            },
                        }));
                    }
                }
            };

            checkSecondaryArtistsExistenceSequentially();
        },
        [status.secondaryArtists.searching, entries], // eslint-disable-line
    );

    useEffect(
        () => {
            if ((existingSecondaryArtists.length + nonExistingSecondaryArtists.length === data?.secondaryArtists?.length) && data?.secondaryArtists?.length !== 0) {
                setStatus((prev) => ({
                    ...prev,
                    secondaryArtists: {
                        ...prev.secondaryArtists,
                        loading: false,
                        searching: false,
                        success: true,
                    },
                    tracks: {
                        ...prev.tracks,
                        loading: true,
                        searching: true,
                    },
                }));
            }
        },
        [existingSecondaryArtists, nonExistingSecondaryArtists], // eslint-disable-line
    );

    useEffect(
        () => {
            if (status.tracks.searching) {
                const releaseTracksIsrcs = data?.tracks?.map(({ isrc }) => isrc);

                setTracksIsrcs(releaseTracksIsrcs);
            }
        },
        [status.tracks.searching], // eslint-disable-line
    );

    useEffect(
        () => {
            if (tracksData && status.tracks.searching && fetchedTracks) {
                setFetchedTracks(false);

                setTracksIsrcs([]);

                getExistingAndNonExistingTracksHandler(
                    {
                        setExistingTracks,
                        setNonExistingTracks,
                        setStatus,
                        tracks: data?.tracks,
                    },
                    { tracks: tracksData },
                );
            }
        },
        [status.tracks.searching, tracksData, fetchedTracks], // eslint-disable-line
    );

    useEffect(
        () => {
            const addExistingTracksSequentially = async () => {
                if ((existingTracks.length + nonExistingTracks.length === data?.tracks?.length) && data?.tracks?.length > 0) {
                    if (existingTracks.length > 0) {
                        for (let i = 0; i < existingTracks?.length; i++) { // eslint-disable-line
                            const track = existingTracks[i];

                            await addExistingTrackHandler( // eslint-disable-line
                                {
                                    addReleaseTrack,
                                    originalData: data,
                                    release: {
                                        ...data?.release,
                                        id: releaseData?.createOneRelease?.id,
                                    },
                                    setAddedExistingTracks,
                                    setCurrentStep,
                                    setInvalidImporterRelease,
                                    setStatus,
                                    track,
                                },
                            );
                        }
                    }
                }
            };

            addExistingTracksSequentially();
        },
        [existingTracks, nonExistingTracks], // eslint-disable-line
    );

    useEffect(
        () => {
            const uploadNonExistingTracksFilesSequentially = async () => {
                if ((existingTracks.length + nonExistingTracks.length === data?.tracks?.length) && data?.tracks?.length > 0) {
                    if (((existingTracks.length === addedExistingTracks.length) && addedExistingTracks.length > 0) || existingTracks.length === 0) {
                        if (nonExistingTracks.length > 0) {
                            for (let i = 0; i < nonExistingTracks?.length; i++) { // eslint-disable-line
                                const track = nonExistingTracks[i];

                                await uploadNonExistingTrackFileHandler( // eslint-disable-line
                                    {
                                        formMethods,
                                        originalData: data,
                                        release: data?.release,
                                        setCurrentStep,
                                        setImporting,
                                        setInvalidImporterRelease,
                                        setStatus,
                                        setUploadedTracksFiles,
                                        track,
                                        uploadFile,
                                    },
                                );
                            }
                        }
                    }
                }
            };

            uploadNonExistingTracksFilesSequentially();
        },
        [existingTracks, nonExistingTracks, addedExistingTracks], // eslint-disable-line
    );

    useEffect(
        () => {
            const createNonExistingTracksSequentially = async () => {
                if ((uploadedTracksFiles.length === nonExistingTracks.length) && uploadedTracksFiles.length > 0) {
                    for (let i = 0; i < uploadedTracksFiles?.length; i++) { // eslint-disable-line
                        const track = uploadedTracksFiles[i];

                        await createNonExistingTrackHandler( // eslint-disable-line
                            {
                                artistId,
                                createTrack,
                                existingSecondaryArtists,
                                nonExistingSecondaryArtists,
                                originalData: data,
                                release: {
                                    ...data?.release,
                                    id: releaseData?.createOneRelease?.id,
                                },
                                setCreatedNonExistingTracks,
                                setCurrentStep,
                                setInvalidImporterRelease,
                                setStatus,
                                track,
                            },
                        );
                    }
                }
            };

            createNonExistingTracksSequentially();
        },
        [uploadedTracksFiles, nonExistingTracks], // eslint-disable-line
    );

    useEffect(
        () => {
            if ((addedExistingTracks.length + createdNonExistingTracks.length === data?.tracks?.length) && data?.tracks?.length > 0) {
                if (data?.videos?.length > 0) {
                    setStatus((prev) => ({
                        ...prev,
                        tracks: {
                            ...prev.tracks,
                            loading: false,
                            searching: false,
                            success: true,
                        },
                        videos: {
                            ...prev.videos,
                            loading: true,
                        },
                    }));
                } else {
                    setStatus((prev) => ({
                        ...prev,
                        documents: {
                            ...prev.documents,
                            loading: data?.documents?.length > 0,
                            success: data?.documents?.length > 0 ? null : true,
                        },
                        tracks: {
                            ...prev.tracks,
                            loading: false,
                            searching: false,
                            success: true,
                        },
                        videos: {
                            ...prev.videos,
                            loading: false,
                            success: true,
                        },
                    }));
                }
            }
        },
        [addedExistingTracks, createdNonExistingTracks], // eslint-disable-line
    );

    useEffect(
        () => {
            const uploadVideosSequentially = async () => {
                if (status.videos.loading) {
                    for (let i = 0; i < data?.videos?.length; i++) { // eslint-disable-line
                        const video = data?.videos[i];

                        await uploadVideoFileHandler( // eslint-disable-line
                            {
                                formMethods,
                                originalData: data,
                                release: {
                                    ...data?.release,
                                    id: releaseData?.createOneRelease?.id,
                                },
                                setCreatedVideos,
                                setCurrentStep,
                                setInvalidImporterRelease,
                                setStatus,
                                trackId: addedExistingTracks?.find(({ isrc }) => isrc === video?.isrc)?.id || createdNonExistingTracks?.find(({ isrc }) => isrc === video?.isrc)?.id,
                                uploadFile,
                                video: {
                                    ...video,
                                    type: entries?.videosTypes[i],
                                },
                            },
                        );
                    }
                }
            };

            uploadVideosSequentially();
        },
        [status.videos.loading], // eslint-disable-line
    );

    useEffect(
        () => {
            if (createdVideos.length === data?.videos?.length && data?.videos?.length > 0) {
                if (data?.documents?.length > 0) {
                    setStatus((prev) => ({
                        ...prev,
                        documents: {
                            ...prev.documents,
                            loading: true,
                        },
                        videos: {
                            ...prev.videos,
                            loading: false,
                            success: true,
                        },
                    }));
                } else {
                    setStatus((prev) => ({
                        ...prev,
                        documents: {
                            ...prev.documents,
                            loading: false,
                            success: true,
                        },
                        videos: {
                            ...prev.videos,
                            loading: false,
                            success: true,
                        },
                    }));
                }
            }
        },
        [createdVideos], // eslint-disable-line
    );

    useEffect(
        () => {
            const uploadDocumentsFilesSequentially = async () => {
                if (status.documents.loading) {
                    for (let i = 0; i < data?.documents?.length; i++) { // eslint-disable-line
                        const document = data?.documents[i];

                        await uploadDocumentFileHandler( // eslint-disable-line
                            {
                                document: {
                                    ...document,
                                    type: entries?.documentsTypes.find(({ index }) => index === i),
                                },
                                formMethods,
                                originalData: data,
                                release: {
                                    ...data?.release,
                                    id: releaseData?.createOneRelease?.id,
                                },
                                setCurrentStep,
                                setInvalidImporterRelease,
                                setStatus,
                                setUploadedDocumentsFiles,
                                uploadFile,
                            },
                        );
                    }
                }
            };

            uploadDocumentsFilesSequentially();
        },
        [status.documents.loading, stopImporting.start], // eslint-disable-line
    );

    useEffect(
        () => {
            const createDocumentsSequentially = async () => {
                if ((uploadedDocumentsFiles.length === data?.documents?.length) && data?.documents?.length > 0) {
                    for (let i = 0; i < uploadedDocumentsFiles?.length; i++) { // eslint-disable-line
                        const document = uploadedDocumentsFiles[i];

                        await createDocumentHandler( // eslint-disable-line
                            {
                                createReleaseDocument,
                                document,
                                originalData: data,
                                release: {
                                    ...data?.release,
                                    id: releaseData?.createOneRelease?.id,
                                },
                                ...document?.isrc && { trackId: addedExistingTracks?.find(({ isrc }) => isrc === document?.isrc)?.id || createdNonExistingTracks?.find(({ isrc }) => isrc === document?.isrc)?.id },
                                setCreatedDocuments,
                                setCurrentStep,
                                setInvalidImporterRelease,
                                setStatus,
                            },
                        );
                    }
                }
            };

            createDocumentsSequentially();
        },
        [uploadedDocumentsFiles], // eslint-disable-line
    );

    useEffect(
        () => {
            if ((createdDocuments.length === data?.documents?.length) && data?.documents?.length > 0) {
                setStatus((prev) => ({
                    ...prev,
                    documents: {
                        ...prev.documents,
                        loading: false,
                        success: true,
                    },
                }));
            }
        },
        [createdDocuments], // eslint-disable-line
    );

    useEffect(
        () => {
            if (status.documents.success) dispatch(setCompletedImporterRelease(data?.release));
        },
        [status.documents.success], // eslint-disable-line
    );

    useEffect(
        () => {
            if (!stopImporting.finish && !stopImporting.start) {
                if (
                    (status.documents.success === false && status.videos.success && status.tracks.success)
                    || (status.videos.success === false && status.tracks.success)
                    || status.tracks.success === false
                    || status.secondaryArtists.success === false
                    || status.release.success === false
                ) if (releaseData) deleteRelease(releaseData?.createOneRelease?.id);
            }
        },
        [ // eslint-disable-line
            status.documents.success,
            status.videos.success,
            status.tracks.success,
            status.secondaryArtists.success,
            status.release.success,
        ],
    );

    useEffect(
        () => {
            setStatus((prev) => ({
                ...prev,
                release: {
                    ...prev.release,
                    deletingReleaseLoading: !!deleteReleaseLoading,
                },
            }));
        },
        [deleteReleaseLoading],
    );

    useEffect(
        () => { // eslint-disable-line
            const {
                addingTrack,
                creatingArtist,
                creatingDocument,
                creatingNote,
                creatingOwnership,
                creatingRelease,
                creatingReleaseImage,
                creatingSecondaryArtist,
                creatingTrack,
                creatingVideo,
                searchingArtists,
                searchingTracks,
                uploadingDocumentFile,
                uploadingReleaseImage,
                uploadingTrackFile,
                uploadingVideoFile,
            } = importerConfigData.steps;

            if (currentStep && stopImporting.start) {
                const addStoppedReleaseToInvalidReleasesHandler = () => {
                    dispatch(setInvalidImporterRelease({
                        isStopped: true,
                        originalData: data,
                        release: {
                            data: data?.release,
                            message: t("messages.importerMsgs.stoppedRelease"),
                        },
                    }));

                    dispatch(stopReleaseImporting({
                        finish: true,
                        start: false,
                    }));
                };

                switch (currentStep) {
                case searchingArtists:
                    cancelArtistsListRequest();

                    addStoppedReleaseToInvalidReleasesHandler();

                    break;

                case creatingArtist:
                    cancelCreateArtistRequest();

                    addStoppedReleaseToInvalidReleasesHandler();

                    setStatus((prev) => ({
                        ...prev,
                        artist: {
                            ...prev.artist,
                            loading: false,
                            searching: false,
                            success: false,
                        },
                    }));

                    break;

                case uploadingReleaseImage:
                    cancelUploadFileRequest();

                    dispatch(stopReleaseImporting({
                        finish: true,
                        start: false,
                    }));

                    break;

                case creatingReleaseImage:
                    cancelCreateImageRequest();

                    addStoppedReleaseToInvalidReleasesHandler();

                    setStatus((prev) => ({
                        ...prev,
                        releaseImage: {
                            ...prev.releaseImage,
                            loading: false,
                            success: false,
                        },
                    }));

                    break;

                case creatingRelease:
                    if (releaseData && status.release.createReleaseSuccess) {
                        cancelCreateReleaseRequest();

                        deleteRelease(releaseData?.createOneRelease?.id);

                        addStoppedReleaseToInvalidReleasesHandler();
                    }

                    break;

                case creatingOwnership:
                    if (releaseData && status.release.createReleaseSuccess) {
                        cancelCreateReleaseOwnershipRequest();

                        deleteRelease(releaseData?.createOneRelease?.id);

                        addStoppedReleaseToInvalidReleasesHandler();

                        setStatus((prev) => ({
                            ...prev,
                            release: {
                                ...prev.release,
                                loading: false,
                                ownershipCreated: false,
                                success: false,
                            },
                        }));
                    }

                    break;

                case creatingNote:
                    if (releaseData && status.release.createReleaseSuccess) {
                        cancelCreateReleaseNoteRequest();

                        deleteRelease(releaseData?.createOneRelease?.id);

                        addStoppedReleaseToInvalidReleasesHandler();

                        setStatus((prev) => ({
                            ...prev,
                            release: {
                                ...prev.release,
                                loading: false,
                                noteCreated: false,
                                success: false,
                            },
                        }));
                    }

                    break;

                case creatingSecondaryArtist:
                    if (releaseData && status.release.createReleaseSuccess) {
                        cancelCreateArtistRequest();

                        deleteRelease(releaseData?.createOneRelease?.id);

                        addStoppedReleaseToInvalidReleasesHandler();

                        setStatus((prev) => ({
                            ...prev,
                            secondaryArtists: {
                                ...prev.secondaryArtists,
                                loading: false,
                                searching: false,
                                success: false,
                            },
                        }));
                    }

                    break;

                case searchingTracks:
                    if (releaseData && status.release.createReleaseSuccess) {
                        cancelTracksListRequest();

                        deleteRelease(releaseData?.createOneRelease?.id);

                        addStoppedReleaseToInvalidReleasesHandler();
                    }

                    break;

                case addingTrack:
                    cancelAddTrackToReleaseRequest();

                    deleteRelease(releaseData?.createOneRelease?.id);

                    addStoppedReleaseToInvalidReleasesHandler();

                    setStatus((prev) => ({
                        ...prev,
                        tracks: {
                            ...prev.tracks,
                            loading: false,
                            success: false,
                        },
                    }));

                    break;

                case uploadingTrackFile:
                case uploadingVideoFile:
                case uploadingDocumentFile:
                    cancelUploadFileRequest();

                    deleteRelease(releaseData?.createOneRelease?.id);

                    dispatch(stopReleaseImporting({
                        finish: true,
                        start: false,
                    }));

                    break;

                case creatingTrack:
                    cancelCreateTrackRequest();

                    deleteRelease(releaseData?.createOneRelease?.id);

                    addStoppedReleaseToInvalidReleasesHandler();

                    setStatus((prev) => ({
                        ...prev,
                        tracks: {
                            ...prev.tracks,
                            loading: false,
                            success: false,
                        },
                    }));

                    break;

                case creatingVideo:
                    cancelCreateVideoRequest();

                    deleteRelease(releaseData?.createOneRelease?.id);

                    addStoppedReleaseToInvalidReleasesHandler();

                    setStatus((prev) => ({
                        ...prev,
                        videos: {
                            ...prev.videos,
                            loading: false,
                            success: false,
                        },
                    }));

                    break;

                case creatingDocument:
                    cancelCreateReleaseDocumentRequest();

                    deleteRelease(releaseData?.createOneRelease?.id);

                    addStoppedReleaseToInvalidReleasesHandler();

                    setStatus((prev) => ({
                        ...prev,
                        documents: {
                            ...prev.documents,
                            loading: false,
                            success: false,
                        },
                    }));

                    break;
                }
            } else if (stopImporting.finish && currentStep) {
                switch (currentStep) {
                case creatingSecondaryArtist: cancelCreateArtistRequest();

                    break;

                case addingTrack: cancelAddTrackToReleaseRequest();

                    break;

                case uploadingTrackFile:
                case uploadingVideoFile:
                case uploadingDocumentFile: cancelUploadFileRequest();

                    break;

                case creatingTrack: cancelCreateTrackRequest();

                    break;

                case creatingVideo: cancelCreateVideoRequest();

                    break;

                case creatingDocument: cancelCreateReleaseDocumentRequest();

                    break;
                }
            }
        },
        [currentStep, stopImporting], // eslint-disable-line
    );

    return {
        currentStep,
        importRelease,
        importing,
        initialState,
        setImporting,
        setStatus,
        setValidation,
        status: { ...status },
        validation,
    };
};

export default useImportRelease;
