import { useAuth0 } from "@auth0/auth0-react";
import { Box, CssBaseline } from "@mui/material";
import { Content, Footer, Navbar } from "components";
import { constantsData } from "data";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { logoutHandler } from "utils";

const DriveManagementLayout = () => {
    const [open, setOpen] = useState(false);

    const {
        getAccessTokenSilently,
        isAuthenticated,
        isLoading,
        logout,
    } = useAuth0();

    const { token: tokenConstant } = constantsData;

    useEffect(
        () => {
            if (!isLoading) {
                if (isAuthenticated) {
                    if (!Cookies.get(tokenConstant)) {
                        getAccessTokenSilently().then((token) => {
                            Cookies.set(
                                tokenConstant,
                                token,
                            );
                        });
                    }
                } else logoutHandler(logout);
            }
        },
        [isAuthenticated, isLoading], // eslint-disable-line
    );

    return (
        <>
            <Box display="flex">
                <CssBaseline />
                <Navbar
                    open={open}
                    isDriveManagementNavbar
                    onOpenSidebar={() => setOpen(true)}
                />
                <Content open={open}>
                    <Outlet />
                </Content>
            </Box>
            <Footer />
        </>
    );
};

export default DriveManagementLayout;
