import { useAuth0 } from "@auth0/auth0-react";
import {
    constantsData,
    fileData,
    identifiersData,
    importerConfigData,
    namesData,
    statusTypesData,
} from "data";
import { useCreateImage, useCreateReleaseLyricsFile, useCreateVideo } from "hooks";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
    openAlert,
    setRefetchRevenueReportsList,
    setRevenueReportTemplateData,
    setXmlDownloadLink,
} from "redux/actions";
import {
    axios,
    checkFileValidationHandler,
    deleteFileHandler,
    displayErrorHandler,
    getReleaseFileErrorMessageHandler,
} from "utils";

const useUploadFile = (
    trackId,
    releaseId,
    withoutRefetch,
    withoutAlert,
) => {
    const [controller, setController] = useState(null);

    const [filesData, setFilesData] = useState({});

    const [filesLoading, setFilesLoading] = useState({});

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { getAccessTokenSilently } = useAuth0();

    const {
        cancelRequest: cancelCreateImageRequest,
        createImage,
    } = useCreateImage(withoutAlert);

    const { createReleaseLyricsFile } = useCreateReleaseLyricsFile(trackId);

    const {
        cancelRequest: cancelCreateVideoRequest,
        createVideo,
    } = useCreateVideo(
        trackId,
        releaseId,
        withoutRefetch,
        withoutAlert,
    );

    const {
        image,
        lyrics,
        releaseClientLinks,
        revenueReport,
        revenueReportTemplate,
        track,
        video,
        xml,
    } = fileData.names;

    const { uploadFilePending: uploadFilePendingToastName } = namesData.toasts;

    const { toast: toastId } = identifiersData.ids;

    const uploadFile = async (
        file,
        uploadMutation,
        formMethods,
        name,
        id,
        otherData,
    ) => {
        const {
            importerData,
            setModalData,
            templateData,
            videoType,
        } = otherData || {};

        if (checkFileValidationHandler(
            file,
            name,
        )) {
            setFilesLoading({
                ...filesLoading,
                [name]: true,
            });

            if (!importerData) {
                toast.info(
                    () => t(
                        "messages.uploadMsgs.pending",
                        { entity: t("labels.file") },
                    ),
                    { toastId: `${uploadFilePendingToastName}_${toastId}` },
                );
            }

            const token = await getAccessTokenSilently();

            const bodyFormData = new FormData(); //eslint-disable-line

            const newController = new AbortController();

            setController(newController);

            if (importerData?.uploadingReleaseImage) importerData?.setCurrentStep(importerConfigData.steps.uploadingReleaseImage);

            if (importerData?.uploadingTrackFile) importerData?.setCurrentStep(importerConfigData.steps.uploadingTrackFile);

            if (importerData?.uploadingVideoFile) importerData?.setCurrentStep(importerConfigData.steps.uploadingVideoFile);

            if (importerData?.uploadingDocumentFile) importerData?.setCurrentStep(importerConfigData.steps.uploadingDocumentFile);

            const operationsData = {
                query: uploadMutation,
                ...name === xml && otherData?.xmlForm ? {
                    variables: {
                        ...otherData,
                        file,
                    },
                } : {
                    variables: {
                        clientId: id,
                        file,
                    },
                },
                ...name === revenueReportTemplate && {
                    variables: {
                        clientId: id,
                        file,
                        templateName: templateData,
                    },
                },
                ...name === revenueReport && {
                    variables: {
                        clientId: id,
                        file,
                        templateId: templateData,
                    },
                },
            };

            bodyFormData.append(
                "operations",
                JSON.stringify(operationsData),
            );

            bodyFormData.append(
                "map",
                JSON.stringify({ 0: ["variables.file"] }),
            );

            bodyFormData.append(
                "0",
                file,
            );

            await axios(
                true,
                null,
                true,
            )({
                data: bodyFormData,
                headers: { Authorization: `Bearer ${token}` },
                method: "post",
                signal: newController.signal,
            }).then(async ({ data }) => {
                let fileUploadType = "";
                switch (name) {
                case track: fileUploadType = "uploadTrack";
                    break;
                case xml: fileUploadType = "uploadXMLFIle";
                    break;
                default: fileUploadType = "uploadFile";
                }

                const fileId = data?.data?.[fileUploadType]?.id;

                if (fileId) {
                    switch (name) {
                    case image:
                        await createImage(
                            fileId,
                            formMethods,
                            name,
                            importerData,
                            setFilesData,
                        );
                        break;
                    case lyrics:
                        await createReleaseLyricsFile(fileId);
                        break;
                    case video:
                        await createVideo(
                            fileId,
                            videoType,
                            importerData,
                        );
                        break;
                    case releaseClientLinks:
                        break;
                    default:
                        formMethods.setValue(
                            name,
                            fileId,
                        );
                        break;
                    }
                }

                if (name === xml && otherData?.xmlForm) {
                    deleteFileHandler(
                        setFilesData,
                        formMethods,
                        name,
                    );

                    if (data?.data?.createdynamicXML) {
                        dispatch(setXmlDownloadLink(data?.data?.createdynamicXML));

                        dispatch(openAlert(
                            t(
                                "messages.uploadMsgs.success",
                                { entity: t("labels.xml") },
                            ),
                            statusTypesData.success,
                        ));
                    } else if (data?.errors[0]?.message) {
                        displayErrorHandler(
                            `${t(
                                "messages.uploadMsgs.fail",
                                { entity: t("labels.file") },
                            )}, ${data?.errors[0]?.message}`,
                            dispatch,
                            openAlert,
                        );
                    }
                }

                if (name === revenueReportTemplate) {
                    if (data?.data?.createRevenueTemplate) {
                        const {
                            id: templateId,
                            templateName,
                            templateObj,
                        } = data?.data?.createRevenueTemplate || {};

                        dispatch(setRevenueReportTemplateData({
                            data: templateObj,
                            id: templateId,
                            name: templateName,
                        }));

                        setModalData((prev) => ({
                            ...prev,
                            open: false,
                        }));

                        dispatch(openAlert(
                            t(
                                "messages.uploadMsgs.success",
                                { entity: t("labels.template") },
                            ),
                            statusTypesData.success,
                        ));
                    } else {
                        dispatch(openAlert(
                            data?.errors?.[0]?.message,
                            statusTypesData.error,
                        ));
                    }
                }

                if (name === revenueReport) {
                    if (data?.data?.importRevenueReport) {
                        dispatch(setRefetchRevenueReportsList(true));

                        setModalData((prev) => ({
                            ...prev,
                            open: false,
                        }));

                        dispatch(openAlert(
                            t(
                                "messages.uploadMsgs.success",
                                { entity: t("revenueReports.entity") },
                            ),
                            statusTypesData.success,
                        ));
                    } else {
                        dispatch(openAlert(
                            data?.errors?.[0]?.message,
                            statusTypesData.error,
                        ));
                    }
                }

                if (!otherData?.xmlForm) {
                    setFilesData((prev) => ({
                        ...prev,
                        [name]: {
                            ...prev[name],
                            ...fileId && { id: fileId },
                            name: file?.name,
                            ...name === releaseClientLinks && { data: data?.data?.importReleaseLinks },
                        },
                    }));
                }

                if (importerData) {
                    if (importerData?.uploadingTrackFile) {
                        importerData?.setUploadedTracksFiles((prev) => [
                            ...prev,
                            {
                                ...importerData?.track,
                                fileId,
                            },
                        ]);
                    }

                    if (importerData?.uploadingDocumentFile) {
                        importerData?.setUploadedDocumentsFiles((prev) => [
                            ...prev,
                            {
                                ...importerData?.document,
                                fileId,
                            },
                        ]);
                    }
                }
            })["catch"]((err) => {
                if (!withoutAlert) {
                    displayErrorHandler(
                        err.message === constantsData.networkError ? t("messages.networkFailure") : `${t(
                            "messages.uploadMsgs.fail",
                            { entity: t("labels.file") },
                        )}, ${err.message}`,
                        dispatch,
                        openAlert,
                    );
                }

                if (importerData) {
                    if (importerData?.uploadingReleaseImage) {
                        dispatch(importerData?.setInvalidImporterRelease(
                            {
                                originalData: importerData?.originalData,
                                release: {
                                    data: importerData?.release,
                                    message: getReleaseFileErrorMessageHandler(
                                        err,
                                        "uploadReleaseImageFailed",
                                        t,
                                    ),
                                },
                                ...err.message === constantsData.networkError && { isNetworkFailed: true },
                                ...err.message === constantsData.canceled && { isStopped: true },
                            },
                        ));

                        importerData?.setStatus((prev) => ({
                            ...prev,
                            releaseImage: {
                                ...prev.releaseImage,
                                loading: false,
                                success: false,
                            },
                        }));

                        if (err.message === constantsData.networkError) importerData?.setImporting(false);
                    }

                    if (importerData?.uploadingTrackFile) {
                        dispatch(importerData?.setInvalidImporterRelease(
                            {
                                originalData: importerData?.originalData,
                                ...err.message === constantsData.networkError && { isNetworkFailed: true },
                                ...err.message === constantsData.canceled ? {
                                    isStopped: true,
                                    release: {
                                        data: importerData?.release,
                                        message: getReleaseFileErrorMessageHandler(
                                            err,
                                            "uploadTrackFileFailed",
                                            t,
                                        ),
                                    },
                                } : {
                                    release: { data: importerData?.release },
                                    track: {
                                        data: importerData?.track,
                                        message: getReleaseFileErrorMessageHandler(
                                            err,
                                            "uploadTrackFileFailed",
                                            t,
                                        ),
                                    },
                                },
                            },
                        ));

                        importerData?.setStatus((prev) => ({
                            ...prev,
                            tracks: {
                                ...prev.tracks,
                                loading: false,
                                success: false,
                            },
                        }));

                        if (err.message === constantsData.networkError) importerData?.setImporting(false);
                    }

                    if (importerData?.uploadingVideoFile) {
                        dispatch(importerData?.setInvalidImporterRelease(
                            {
                                originalData: importerData?.originalData,
                                ...err.message === constantsData.networkError && { isNetworkFailed: true },
                                ...err.message === constantsData.canceled ? {
                                    isStopped: true,
                                    release: {
                                        data: importerData?.release,
                                        message: getReleaseFileErrorMessageHandler(
                                            err,
                                            "uploadVideoFileFailed",
                                            t,
                                        ),
                                    },
                                } : {
                                    release: { data: importerData?.release },
                                    video: {
                                        data: importerData?.video,
                                        message: getReleaseFileErrorMessageHandler(
                                            err,
                                            "uploadVideoFileFailed",
                                            t,
                                        ),
                                    },
                                },
                            },
                        ));

                        importerData?.setStatus((prev) => ({
                            ...prev,
                            videos: {
                                ...prev.videos,
                                loading: false,
                                success: false,
                            },
                        }));

                        if (err.message === constantsData.networkError) importerData?.setImporting(false);
                    }

                    if (importerData?.uploadingDocumentFile) {
                        dispatch(importerData?.setInvalidImporterRelease(
                            {
                                originalData: importerData?.originalData,
                                ...err.message === constantsData.networkError && { isNetworkFailed: true },
                                ...err.message === constantsData.canceled ? {
                                    isStopped: true,
                                    release: {
                                        data: importerData?.release,
                                        message: getReleaseFileErrorMessageHandler(
                                            err,
                                            "uploadDocumentFileFailed",
                                            t,
                                        ),
                                    },
                                } : {
                                    document: {
                                        data: importerData?.document,
                                        message: getReleaseFileErrorMessageHandler(
                                            err,
                                            "uploadDocumentFileFailed",
                                            t,
                                        ),
                                    },
                                    release: { data: importerData?.release },
                                },
                            },
                        ));

                        importerData?.setStatus((prev) => ({
                            ...prev,
                            documents: {
                                ...prev.documents,
                                loading: false,
                                success: false,
                            },
                        }));

                        if (err.message === constantsData.networkError) importerData?.setImporting(false);
                    }
                }
            })["finally"](() => {
                setFilesLoading((prev) => ({
                    ...prev,
                    [name]: false,
                }));

                setController(null);

                if (!importerData) toast.dismiss(`${uploadFilePendingToastName}_${toastId}`);

                if (importerData) importerData?.setCurrentStep(null);
            });
        } else {
            if (!withoutAlert) {
                dispatch(openAlert(
                    t("messages.wrongFormat"),
                    statusTypesData.error,
                ));
            }

            if (importerData) {
                if (importerData?.uploadingReleaseImage) {
                    dispatch(importerData.setInvalidImporterRelease(
                        {
                            originalData: importerData?.originalData,
                            release: {
                                data: importerData?.release,
                                message: t("messages.importerMsgs.wrongReleaseImageFormat"),
                            },
                        },
                    ));

                    importerData?.setStatus((prev) => ({
                        ...prev,
                        releaseImage: {
                            ...prev.releaseImage,
                            loading: false,
                            success: false,
                        },
                    }));
                }

                if (importerData?.uploadingTrackFile) {
                    dispatch(importerData.setInvalidImporterRelease(
                        {
                            originalData: importerData?.originalData,
                            release: { data: importerData?.release },
                            track: {
                                data: importerData?.track,
                                message: t("messages.importerMsgs.wrongTrackFileFormat"),
                            },
                        },
                    ));

                    importerData?.setStatus((prev) => ({
                        ...prev,
                        tracks: {
                            ...prev.tracks,
                            loading: false,
                            success: false,
                        },
                    }));
                }

                if (importerData?.uploadingVideoFile) {
                    dispatch(importerData.setInvalidImporterRelease(
                        {
                            originalData: importerData?.originalData,
                            release: { data: importerData?.release },
                            track: {
                                data: importerData?.track,
                                message: t("messages.importerMsgs.wrongVideoFileFormat"),
                            },
                        },

                    ));

                    importerData?.setStatus((prev) => ({
                        ...prev,
                        videos: {
                            ...prev.videos,
                            loading: false,
                            success: false,
                        },
                    }));
                }

                if (importerData?.uploadingDocumentFile) {
                    dispatch(importerData.setInvalidImporterRelease(
                        {
                            document: {
                                data: importerData?.document,
                                message: t("messages.importerMsgs.wrongDocumentFileFormat"),
                            },
                            originalData: importerData?.originalData,
                            release: { data: importerData?.release },
                        },
                    ));

                    importerData?.setStatus((prev) => ({
                        ...prev,
                        documents: {
                            ...prev.documents,
                            loading: false,
                            success: false,
                        },
                    }));
                }
            }
        }
    };

    return {
        cancelCreateImageRequest,
        cancelCreateVideoRequest,
        cancelRequest: () => controller && controller.abort(),
        filesData,
        filesLoading,
        setFilesData,
        uploadFile,
    };
};

export default useUploadFile;
