import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/material";
import {
    AutoCompleteMenu,
    FileLink,
    Loader,
    UploadInput,
} from "atoms";
import { FormContainer } from "components";
import {
    actionsData,
    fileData,
    identifiersData,
    importRevenueReportFormData,
    inputData,
    selectionsData,
} from "data";
import { uploadFileMutations } from "graph";
import { useClientsList, useGetClientRevenueReportsTemplatesList, useUploadFile } from "hooks";
import { PropTypes } from "prop-types";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useImportRevenueReportSchema } from "schemas";
import { deleteFileHandler, renderFormControllerHandler, setUploadInputPreviousValueHandler } from "utils";

const ImportRevenueReportForm = ({ setModalData }) => {
    const [selectedClient, setSelectedClient] = useState(null);

    const selections = useSelector((state) => state.selectionsReducer);

    const { t } = useTranslation();

    const schema = useImportRevenueReportSchema();

    const formMethods = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const {
        filesData,
        filesLoading,
        setFilesData,
        uploadFile,
    } = useUploadFile();

    const {
        count: clientsCount,
        fetched: clientsFetched,
        loading: clientsLoading,
        page: clientsPage,
        refetch: clientsRefetch,
        searchKey: clientsSearchKey,
    } = useClientsList(
        true,
        true,
    );

    const {
        data,
        loading: revenueReportsTemplatesListLoading,
    } = useGetClientRevenueReportsTemplatesList(selectedClient);

    const {
        control,
        formState: { errors },
        setError,
        setValue,
        watch,
    } = formMethods;

    const {
        client: clientInputName,
        templateName: templateNameInputName,
    } = inputData.names;

    const templatesOptions = data && data.map((template) => ({
        label: template?.templateName,
        value: template?.id,
    }));

    const asyncMenus = {
        clients: {
            count: clientsCount,
            loading: clientsLoading,
            page: clientsPage,
            refetch: clientsRefetch,
            searchKey: clientsSearchKey,
            selectionKey: selectionsData.keys.clients,
        },
    };

    const renderFormControllerChildrenHandler = (
        field,
        label,
        name,
        type,
        isOptional,
        selectionKey,
        isAsyncMenu,
    ) => {
        if (type === inputData.types.file) {
            return (
                <Box mb={1}>
                    <UploadInput
                        accept={fileData.acceptedExtensions.csv}
                        color={errors[name]?.message ? "error" : "primary"}
                        error={errors[name]?.message}
                        field={field}
                        hasError={!!errors[name]?.message}
                        label={t(`labels.${label}`)}
                        loading={filesLoading?.[name]}
                        onChange={(e) => {
                            field.onChange(e.target.value);

                            setFilesData({ [name]: e.target.files[0] });
                        }}
                    />
                    {(filesData?.[name] && watch(name)) && (
                        <FileLink
                            name={filesData?.[name]?.name}
                            deleteAction={() => deleteFileHandler(
                                setFilesData,
                                formMethods,
                                name,
                            )}
                            hasDelete
                        />
                    )}
                </Box>
            );
        }

        return (
            <AutoCompleteMenu
                asyncMenuData={asyncMenus?.[selectionKey]}
                errors={errors}
                getRenderedOption={(option) => option.label}
                isAsyncMenu={isAsyncMenu}
                isOptional={isOptional}
                label={label}
                loading={(name === templateNameInputName && (!selectedClient || revenueReportsTemplatesListLoading))}
                name={name}
                optionLabel={(option) => option.label}
                options={name === clientInputName ? selections?.[selectionKey] : templatesOptions}
                onChange={(_, option) => {
                    if (option) {
                        field.onChange(option.value);

                        if (name === clientInputName) {
                            setSelectedClient(option.value);

                            setValue(
                                templateNameInputName,
                                "",
                            );

                            document.querySelectorAll(`.${identifiersData.classNames.clearIndicator}`).forEach((button, index) => { // eslint-disable-line
                                if (index === 1) button.click();
                            });
                        }
                    } else field.onChange("");
                }}
            />
        );
    };

    useEffect(
        () => {
            setUploadInputPreviousValueHandler(
                watch,
                setError,
                setValue,
                filesData,
                fileData.names.revenueReport,
            );
        },
        [watch(fileData.names.revenueReport)], // eslint-disable-line
    );

    if (clientsLoading && !clientsFetched) return <Loader withoutFullHeight />;

    return (
        <FormContainer
            action={actionsData["import"]}
            formMethods={formMethods}
            loading={filesLoading?.revenueReportFile}
            setModalData={setModalData}
            data={renderFormControllerHandler(
                control,
                importRevenueReportFormData,
                renderFormControllerChildrenHandler,
            )}
            hasCancelButton
            withoutShadow
            onSubmitForm={() => {
                uploadFile(
                    filesData?.revenueReportFile,
                    uploadFileMutations.revenueReport,
                    formMethods,
                    fileData.names.revenueReport,
                    selectedClient,
                    {
                        setModalData,
                        templateData: watch(templateNameInputName),
                    },
                );
            }}
        />
    );
};

export default ImportRevenueReportForm;

ImportRevenueReportForm.propTypes = { setModalData: PropTypes.func };
