/* eslint-disable max-lines */

import { importerConfigData, regexData } from "data";

const {
    albumCodeIsMissing,
    albumNameArIsMissing,
    albumNameEnIsMissing,
    artistCodeExceedLimit,
    artistCodeIsMissing,
    artistCodeNotUnique,
    artistNameArIsMissing,
    artistNameEnIsMissing,
    artistNationalityIsMissing,
    artistNationalityNotExist,
    cLineIsMissing,
    customIdIsMissing,
    documentFileIsMissing,
    documentTypeNotExist,
    genreIsMissing,
    genreNotExist,
    invalidAlbumCode,
    invalidArtistCode,
    invalidCustomId,
    invalidDiscNumber,
    invalidEndDate,
    invalidIsrcLength,
    invalidReleaseDate,
    invalidTrackNumber,
    invalidUpcLength,
    isrcIsMissing,
    labelIsMissing,
    pLineIsMissing,
    releaseDateIsMissing,
    releaseImageIsMissing,
    releaseTypeIsMissing,
    releaseTypeNotExist,
    rightTypeIsMissing,
    rightTypeNotExist,
    territoryIsMissing,
    territoryNotExist,
    trackFileIsMissing,
    trackNumberIsMissing,
    trackTitleArIsMissing,
    trackTitleEnIsMissing,
    upcIsMissing,
    videoFileIsMissing,
    videoTypeIsMissing,
    videoTypeNotExist,
} = importerConfigData.msgs;

export const validateImporterArtistHandler = (
    importerData,
    lists,
    utils,
    isSecondaryArtist,
) => {
    const {
        artist,
        invalidArtists,
        originalData,
        release,
        setEntries,
        setInvalidImporterRelease,
    } = importerData;

    const {
        artistNameAr,
        artistNameEn,
        code,
        nationality,
    } = artist;

    const {
        artists,
        countries,
    } = lists;

    const {
        dispatch,
        translation,
    } = utils;

    if (!artistNameAr) {
        dispatch(setInvalidImporterRelease(
            {
                artist: {
                    data: artist,
                    message: translation("messages.importerMsgs.artistNameArIsMissing"),
                },
                originalData,
                release: { data: release },
            },
        ));

        if (isSecondaryArtist) {
            invalidArtists.push({
                artist,
                message: artistNameArIsMissing,
            });
        }

        return {
            message: artistNameArIsMissing,
            status: false,
        };
    }

    if (!artistNameEn) {
        dispatch(setInvalidImporterRelease(
            {
                artist: {
                    data: artist,
                    message: translation("messages.importerMsgs.artistNameEnIsMissing"),
                },
                originalData,
                release: { data: release },
            },
        ));

        if (isSecondaryArtist) {
            invalidArtists.push({
                artist,
                message: artistNameEnIsMissing,
            });
        }

        return {
            message: artistNameEnIsMissing,
            status: false,
        };
    }

    if (!code) {
        dispatch(setInvalidImporterRelease(
            {
                artist: {
                    data: artist,
                    message: translation("messages.importerMsgs.artistCodeIsMissing"),
                },
                originalData,
                release: { data: release },
            },
        ));

        if (isSecondaryArtist) {
            invalidArtists.push({
                artist,
                message: artistCodeIsMissing,
            });
        }

        return {
            message: artistCodeIsMissing,
            status: false,
        };
    }

    if (!nationality) {
        dispatch(setInvalidImporterRelease(
            {
                artist: {
                    data: artist,
                    message: translation("messages.importerMsgs.artistNationalityIsMissing"),
                },
                originalData,
                release: { data: release },
            },
        ));

        if (isSecondaryArtist) {
            invalidArtists.push({
                artist,
                message: artistNationalityIsMissing,
            });
        }

        return {
            message: artistNationalityIsMissing,
            status: false,
        };
    }

    if (code && !regexData.number?.test(code)) {
        dispatch(setInvalidImporterRelease(
            {
                artist: {
                    data: artist,
                    message: translation("messages.importerMsgs.invalidArtistCode"),
                },
                originalData,
                release: { data: release },
            },
        ));

        if (isSecondaryArtist) {
            invalidArtists.push({
                artist,
                message: invalidArtistCode,
            });
        }

        return {
            message: invalidArtistCode,
            status: false,
        };
    }

    if (code && code?.toString()?.length > 6) {
        dispatch(setInvalidImporterRelease(
            {
                artist: {
                    data: artist,
                    message: translation("messages.importerMsgs.artistCodeExceedLimit"),
                },
                originalData,
                release: { data: release },
            },
        ));

        if (isSecondaryArtist) {
            invalidArtists.push({
                artist,
                message: artistCodeExceedLimit,
            });
        }

        return {
            message: artistCodeExceedLimit,
            status: false,
        };
    }

    const releaseArtist = artists?.find(({ name }) => name?.trim()?.toLowerCase() === artistNameEn?.trim()?.toLowerCase());

    const releaseArtistCode = artists?.find(({ code: artistCode }) => artistCode === code)?.code;

    if (!releaseArtist && releaseArtistCode) {
        dispatch(setInvalidImporterRelease(
            {
                artist: {
                    data: artist,
                    message: translation("messages.importerMsgs.artistCodeNotUnique"),
                },
                originalData,
                release: { data: release },
            },
        ));

        if (isSecondaryArtist) {
            invalidArtists.push({
                artist,
                message: artistCodeNotUnique,
            });
        }

        return {
            message: artistCodeNotUnique,
            status: false,
        };
    }

    const releaseArtistNationality = countries?.find(({ label }) => label?.trim()?.toLowerCase() === nationality?.trim()?.toLowerCase());

    if (!releaseArtistNationality) {
        dispatch(setInvalidImporterRelease(
            {
                artist: {
                    data: artist,
                    message: translation("messages.importerMsgs.artistNationalityNotExist"),
                },
                originalData,
                release: { data: release },
            },
        ));

        if (isSecondaryArtist) {
            invalidArtists.push({
                artist,
                message: artistNationalityNotExist,
            });
        }

        return {
            message: artistNationalityNotExist,
            status: false,
        };
    }

    setEntries((prev) => ({
        ...prev,
        ...isSecondaryArtist ? {
            secondaryArtistsNationalities: [
                ...prev.secondaryArtistsNationalities,
                { ...releaseArtistNationality },
            ],
        } : { nationality: releaseArtistNationality },
    }));

    if (!isSecondaryArtist) {
        return {
            message: null,
            status: true,
        };
    }
};

export const validateImporterReleaseHandler = ( // eslint-disable-line
    importerData,
    lists,
    utils,
) => {
    const {
        originalData,
        release,
        setEntries,
        setInvalidImporterRelease,
    } = importerData;

    const {
        albumNameAr,
        albumNameEn,
        code,
        discNum,
        endDate,
        genre,
        image,
        ownership,
        releaseDate,
        releaseType,
        rightType,
        upc,
    } = release;

    const {
        cLine,
        label: ownershipLabel,
        pLine,
        territory,
    } = ownership;

    const {
        genres,
        releaseTypes,
        rightTypes,
        territories,
    } = lists;

    const {
        dispatch,
        translation,
    } = utils;

    if (!albumNameAr) {
        dispatch(setInvalidImporterRelease(
            {
                originalData,
                release: {
                    data: release,
                    message: translation("messages.importerMsgs.albumNameArIsMissing"),
                },
            },
        ));

        return {
            message: albumNameArIsMissing,
            status: false,
        };
    }

    if (!albumNameEn) {
        dispatch(setInvalidImporterRelease(
            {
                originalData,
                release: {
                    data: release,
                    message: translation("messages.importerMsgs.albumNameEnIsMissing"),
                },
            },
        ));

        return {
            message: albumNameEnIsMissing,
            status: false,
        };
    }

    if (!upc) {
        dispatch(setInvalidImporterRelease(
            {
                originalData,
                release: {
                    data: release,
                    message: translation("messages.importerMsgs.upcIsMissing"),
                },
            },
        ));

        return {
            message: upcIsMissing,
            status: false,
        };
    }

    if (!code) {
        dispatch(setInvalidImporterRelease(
            {
                originalData,
                release: {
                    data: release,
                    message: translation("messages.importerMsgs.albumCodeIsMissing"),
                },
            },
        ));

        return {
            message: albumCodeIsMissing,
            status: false,
        };
    }

    if (!genre) {
        dispatch(setInvalidImporterRelease(
            {
                originalData,
                release: {
                    data: release,
                    message: translation("messages.importerMsgs.genreIsMissing"),
                },
            },
        ));

        return {
            message: genreIsMissing,
            status: false,
        };
    }

    if (!image) {
        dispatch(setInvalidImporterRelease(
            {
                originalData,
                release: {
                    data: release,
                    message: translation("messages.importerMsgs.releaseImageIsMissing"),
                },
            },
        ));

        return {
            message: releaseImageIsMissing,
            status: false,
        };
    }

    if (!releaseDate) {
        dispatch(setInvalidImporterRelease(
            {
                originalData,
                release: {
                    data: release,
                    message: translation("messages.importerMsgs.releaseDateIsMissing"),
                },
            },
        ));

        return {
            message: releaseDateIsMissing,
            status: false,
        };
    }

    if (!releaseType) {
        dispatch(setInvalidImporterRelease(
            {
                originalData,
                release: {
                    data: release,
                    message: translation("messages.importerMsgs.releaseTypeIsMissing"),
                },
            },
        ));

        return {
            message: releaseTypeIsMissing,
            status: false,
        };
    }

    if (!rightType) {
        dispatch(setInvalidImporterRelease(
            {
                originalData,
                release: {
                    data: release,
                    message: translation("messages.importerMsgs.rightTypeIsMissing"),
                },
            },
        ));

        return {
            message: rightTypeIsMissing,
            status: false,
        };
    }

    if (cLine || pLine || ownershipLabel || territory) {
        if (!cLine) {
            dispatch(setInvalidImporterRelease(
                {
                    originalData,
                    release: {
                        data: release,
                        message: translation("messages.importerMsgs.cLineIsMissing"),
                    },
                },
            ));

            return {
                message: cLineIsMissing,
                status: false,
            };
        }

        if (!pLine) {
            dispatch(setInvalidImporterRelease(
                {
                    originalData,
                    release: {
                        data: release,
                        message: translation("messages.importerMsgs.pLineIsMissing"),
                    },
                },
            ));

            return {
                message: pLineIsMissing,
                status: false,
            };
        }

        if (!ownershipLabel) {
            dispatch(setInvalidImporterRelease(
                {
                    originalData,
                    release: {
                        data: release,
                        message: translation("messages.importerMsgs.labelIsMissing"),
                    },
                },
            ));

            return {
                message: labelIsMissing,
                status: false,
            };
        }

        if (!territory) {
            dispatch(setInvalidImporterRelease(
                {
                    originalData,
                    release: {
                        data: release,
                        message: translation("messages.importerMsgs.territoryIsMissing"),
                    },
                },
            ));

            return {
                message: territoryIsMissing,
                status: false,
            };
        }

        const releaseTerritory = territories?.find(({ label: territoryLabel }) => territoryLabel?.trim()?.toLowerCase() === territory?.trim()?.toLowerCase());

        if (!releaseTerritory) {
            dispatch(setInvalidImporterRelease(
                {
                    originalData,
                    release: {
                        data: release,
                        message: translation("messages.importerMsgs.territoryNotExist"),
                    },
                },
            ));

            return {
                message: territoryNotExist,
                status: false,
            };
        }

        setEntries((prev) => ({
            ...prev,
            territory: releaseTerritory,
        }));
    }

    if (releaseDate && releaseDate === translation("messages.inputMsgs.invalidDate")) {
        dispatch(setInvalidImporterRelease(
            {
                originalData,
                release: {
                    data: release,
                    message: translation("messages.importerMsgs.invalidReleaseDate"),
                },
            },
        ));

        return {
            message: invalidReleaseDate,
            status: false,
        };
    }

    if (endDate && endDate === translation("messages.inputMsgs.invalidDate")) {
        dispatch(setInvalidImporterRelease(
            {
                originalData,
                release: {
                    data: release,
                    message: translation("messages.importerMsgs.invalidEndDate"),
                },
            },
        ));

        return {
            message: invalidEndDate,
            status: false,
        };
    }

    if (code && !regexData.number?.test(code)) {
        dispatch(setInvalidImporterRelease(
            {
                originalData,
                release: {
                    data: release,
                    message: translation("messages.importerMsgs.invalidAlbumCode"),
                },
            },
        ));

        return {
            message: invalidAlbumCode,
            status: false,
        };
    }

    if (discNum && !regexData.number?.test(discNum)) {
        dispatch(setInvalidImporterRelease(
            {
                originalData,
                release: {
                    data: release,
                    message: translation("messages.importerMsgs.invalidDiscNumber"),
                },
            },
        ));

        return {
            message: invalidDiscNumber,
            status: false,
        };
    }

    if (upc && upc.length !== 13) {
        dispatch(setInvalidImporterRelease(
            {
                originalData,
                release: {
                    data: release,
                    message: translation("messages.importerMsgs.invalidUpcLength"),
                },
            },
        ));

        return {
            message: invalidUpcLength,
            status: false,
        };
    }

    const releaseGenre = genres?.find(({ label }) => label?.trim()?.toLowerCase() === genre?.trim()?.toLowerCase());

    const releaseRightType = rightTypes?.find(({ label }) => label?.trim()?.toLowerCase() === rightType?.trim()?.toLowerCase());

    const releaseTypeObject = releaseTypes?.find(({ label }) => label?.trim()?.toLowerCase() === releaseType?.trim()?.toLowerCase());

    if (!releaseGenre) {
        dispatch(setInvalidImporterRelease(
            {
                originalData,
                release: {
                    data: release,
                    message: translation("messages.importerMsgs.genreNotExist"),
                },
            },
        ));

        return {
            message: genreNotExist,
            status: false,
        };
    }

    if (!releaseRightType) {
        dispatch(setInvalidImporterRelease(
            {
                originalData,
                release: {
                    data: release,
                    message: translation("messages.importerMsgs.rightTypeNotExist"),
                },
            },
        ));

        return {
            message: rightTypeNotExist,
            status: false,
        };
    }

    if (!releaseTypeObject) {
        dispatch(setInvalidImporterRelease(
            {
                originalData,
                release: {
                    data: release,
                    message: translation("messages.importerMsgs.releaseTypeNotExist"),
                },
            },
        ));

        return {
            message: releaseTypeNotExist,
            status: false,
        };
    }

    setEntries((prev) => ({
        ...prev,
        genre: releaseGenre,
        releaseType: releaseTypeObject,
        rightType: releaseRightType,
    }));

    return {
        message: null,
        status: true,
    };
};

export const validateImporterSecondaryArtistsHandler = (
    importerData,
    lists,
    utils,
) => {
    const invalidArtists = [];

    const { secondaryArtists } = importerData;

    if (secondaryArtists?.length > 0) {
        secondaryArtists?.forEach(({
            artistNameAr,
            artistNameEn,
            code,
            nationality,
        }) => {
            validateImporterArtistHandler(
                {
                    ...importerData,
                    artist: {
                        artistNameAr,
                        artistNameEn,
                        code,
                        nationality,
                    },
                    invalidArtists,
                },
                lists,
                utils,
                true,
            );
        });
    } else {
        return {
            message: null,
            status: true,
        };
    }

    if (invalidArtists.length > 0) {
        return {
            artists: invalidArtists.map(({ artist }) => artist),
            messages: invalidArtists.map(({ message }) => message),
            status: false,

        };
    }

    return {
        message: null,
        status: true,
    };
};

export const validateImporterTracksHandler = (
    importerData,
    utils,
) => {
    const invalidTracks = [];

    const {
        originalData,
        release,
        setInvalidImporterRelease,
        tracks,
    } = importerData;

    const {
        dispatch,
        translation,
    } = utils;

    tracks?.forEach((track) => {
        const {
            customId,
            file,
            isrc,
            trackNum,
            trackTitleAr,
            trackTitleEn,
        } = track;

        if (!trackTitleAr) {
            dispatch(setInvalidImporterRelease(
                {
                    originalData,
                    release: { data: release },
                    track: {
                        data: track,
                        message: translation("messages.importerMsgs.trackTitleArIsMissing"),
                    },
                },
            ));

            invalidTracks.push({
                message: trackTitleArIsMissing,
                track,
            });

            return;
        }

        if (!trackTitleEn) {
            dispatch(setInvalidImporterRelease(
                {
                    originalData,
                    release: { data: release },
                    track: {
                        data: track,
                        message: translation("messages.importerMsgs.trackTitleEnIsMissing"),
                    },
                },
            ));

            invalidTracks.push({
                message: trackTitleEnIsMissing,
                track,
            });

            return;
        }

        if (!isrc) {
            dispatch(setInvalidImporterRelease(
                {
                    originalData,
                    release: { data: release },
                    track: {
                        data: track,
                        message: translation("messages.importerMsgs.isrcIsMissing"),
                    },
                },
            ));

            invalidTracks.push({
                message: isrcIsMissing,
                track,
            });

            return;
        }

        if (!trackNum) {
            dispatch(setInvalidImporterRelease(
                {
                    originalData,
                    release: { data: release },
                    track: {
                        data: track,
                        message: translation("messages.importerMsgs.trackNumberIsMissing"),
                    },
                },
            ));

            invalidTracks.push({
                message: trackNumberIsMissing,
                track,
            });

            return;
        }

        if (!file) {
            dispatch(setInvalidImporterRelease(
                {
                    originalData,
                    release: { data: release },
                    track: {
                        data: track,
                        message: translation("messages.importerMsgs.trackFileIsMissing"),
                    },
                },
            ));

            invalidTracks.push({
                message: trackFileIsMissing,
                track,
            });

            return;
        }

        if (!customId) {
            dispatch(setInvalidImporterRelease(
                {
                    originalData,
                    release: { data: release },
                    track: {
                        data: track,
                        message: translation("messages.importerMsgs.customIdIsMissing"),
                    },
                },
            ));

            invalidTracks.push({
                message: customIdIsMissing,
                track,
            });

            return;
        }

        if (customId && !regexData.number?.test(customId)) {
            dispatch(setInvalidImporterRelease(
                {
                    originalData,
                    release: { data: release },
                    track: {
                        data: track,
                        message: translation("messages.importerMsgs.invalidCustomId"),
                    },
                },
            ));

            invalidTracks.push({
                message: invalidCustomId,
                track,
            });

            return;
        }

        if (trackNum && !regexData.number?.test(trackNum)) {
            dispatch(setInvalidImporterRelease(
                {
                    originalData,
                    release: { data: release },
                    track: {
                        data: track,
                        message: translation("messages.importerMsgs.invalidTrackNumber"),
                    },
                },
            ));

            invalidTracks.push({
                message: invalidTrackNumber,
                track,
            });

            return;
        }

        if (isrc && isrc.length !== 12) {
            dispatch(setInvalidImporterRelease(
                {
                    originalData,
                    release: { data: release },
                    track: {
                        data: track,
                        message: translation("messages.importerMsgs.invalidIsrcLength"),
                    },
                },
            ));

            invalidTracks.push({
                message: invalidIsrcLength,
                track,
            });
        }
    });

    if (invalidTracks.length > 0) {
        return {
            messages: invalidTracks.map(({ message }) => message),
            status: false,
            tracks: invalidTracks.map(({ track }) => track),

        };
    }

    return {
        message: null,
        status: true,
    };
};

export const validateImporterVideosHandler = (
    importerData,
    lists,
    utils,
) => {
    const {
        originalData,
        release,
        setEntries,
        setInvalidImporterRelease,
        videos,
    } = importerData;

    const { videoTypes } = lists;

    const {
        dispatch,
        translation,
    } = utils;

    const invalidVideos = [];

    videos?.forEach((video) => {
        const {
            file,
            type,
        } = video;

        const videoType = videoTypes?.find(({ label }) => label?.trim()?.toLowerCase() === type?.trim()?.toLowerCase());

        if (!type) {
            dispatch(setInvalidImporterRelease(
                {
                    originalData,
                    release: { data: release },
                    video: {
                        data: video,
                        message: translation("messages.importerMsgs.videoTypeIsMissing"),
                    },
                },
            ));

            invalidVideos.push({
                message: videoTypeIsMissing,
                video,
            });

            return;
        }

        if (!file) {
            dispatch(setInvalidImporterRelease(
                {
                    originalData,
                    release: { data: release },
                    video: {
                        data: video,
                        message: translation("messages.importerMsgs.videoFileIsMissing"),
                    },
                },
            ));

            invalidVideos.push({
                message: videoFileIsMissing,
                video,
            });

            return;
        }

        if (!videoType) {
            dispatch(setInvalidImporterRelease(
                {
                    originalData,
                    release: { data: release },
                    video: {
                        data: video,
                        message: translation("messages.importerMsgs.videoTypeNotExist"),
                    },
                },
            ));

            invalidVideos.push({
                message: videoTypeNotExist,
                video,
            });

            return;
        }

        setEntries((prev) => ({
            ...prev,
            videosTypes: [
                ...prev.videosTypes,
                { ...videoType },
            ],
        }));
    });

    if (invalidVideos.length > 0) {
        return {
            messages: invalidVideos.map(({ message }) => message),
            status: false,
            videos: invalidVideos.map(({ video }) => video),

        };
    }

    return {
        message: null,
        status: true,
    };
};

export const validateImporterDocumentsHandler = (
    importerData,
    lists,
    utils,
) => {
    const {
        documents,
        originalData,
        release,
        setEntries,
        setInvalidImporterRelease,
    } = importerData;

    const { documentTypes } = lists;

    const {
        dispatch,
        translation,
    } = utils;

    const invalidDocuments = [];

    documents?.forEach((document, index) => {
        const {
            file,
            type,
        } = document;

        let documentType = null;

        if (type) documentType = documentTypes?.find(({ label }) => label?.trim()?.toLowerCase() === type?.trim()?.toLowerCase());

        if (!file) {
            dispatch(setInvalidImporterRelease(
                {
                    document: {
                        data: document,
                        message: translation("messages.importerMsgs.documentFileIsMissing"),
                    },
                    originalData,
                    release: { data: release },
                },
            ));

            invalidDocuments.push({
                document,
                message: documentFileIsMissing,
            });

            return;
        }

        if (!documentType && type) {
            dispatch(setInvalidImporterRelease(
                {
                    document: {
                        data: document,
                        message: translation("messages.importerMsgs.documentTypeNotExist"),
                    },
                    originalData,
                    release: { data: release },
                },
            ));

            invalidDocuments.push({
                document,
                message: documentTypeNotExist,
            });

            return;
        }

        if (documentType) {
            setEntries((prev) => ({
                ...prev,
                documentsTypes: [
                    ...prev.documentsTypes,
                    {
                        ...documentType,
                        index,
                    },
                ],
            }));
        }
    });

    if (invalidDocuments.length > 0) {
        return {
            documents: invalidDocuments.map(({ document }) => document),
            messages: invalidDocuments.map(({ message }) => message),
            status: false,

        };
    }

    return {
        message: null,
        status: true,
    };
};

export const validateImporterDataHandler = (
    importerData,
    lists,
    utils,
) => validateImporterArtistHandler(
    importerData,
    lists,
    utils,
    false,
).status
    && validateImporterReleaseHandler(
        importerData,
        lists,
        utils,
    ).status
    && validateImporterSecondaryArtistsHandler(
        importerData,
        lists,
        utils,
    ).status
    && validateImporterTracksHandler(
        importerData,
        utils,
    ).status
    && validateImporterVideosHandler(
        importerData,
        lists,
        utils,
    ).status
    && validateImporterDocumentsHandler(
        importerData,
        lists,
        utils,
    ).status;
