import { useAuth0 } from "@auth0/auth0-react";
import { constantsData } from "data";
import { useApisClient } from "hooks";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { openAlert } from "redux/actions";
import { displayErrorHandler } from "utils";

const useUploadDriveManagementFiles = () => {
    const [loading, setLoading] = useState(false);

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { update } = useApisClient();

    const { logout } = useAuth0();

    const uploadDriveManagementFiles = async (
        folderId,
        files,
        sessionsUrls,
        toastIdsRef,
        setSuccessUploadedFiles,
        setFailedUploadedFiles,
    ) => {
        setLoading(true);

        sessionsUrls.forEach((sessionUrl, index) => {
            update(
                sessionUrl.data.sessionUrl.replace(
                    window?.__RUNTIME_CONFIG__?.GOOGLE_UPLOAD_FILES_API, // eslint-disable-line
                    window?.__RUNTIME_CONFIG__?.GOOGLE_MIDDLEWARE_PROXY_API, // eslint-disable-line
                ),
                null,
                files[index],
                null,
                {
                    file: files[index],
                    folderId,
                    isGoogleFilesUpload: true,
                    setFailedUploadedFiles,
                    setSuccessUploadedFiles,
                    toastIdsRef,
                },
            )["catch"]((err) => {
                displayErrorHandler(
                    err.response.data.message === constantsData.networkError ? t("messages.networkFailure") : err.response.data.message,
                    dispatch,
                    openAlert,
                    err.response.status,
                    logout,
                );
            })["finally"](() => {
                setLoading(false);
            });
        });
    };

    return {
        loading,
        uploadDriveManagementFiles,
    };
};

export default useUploadDriveManagementFiles;
