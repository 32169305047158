import { gql } from "@apollo/client";

export const editRevenueReportMutation = gql`
    mutation editRevenueReport(
        $data: RevenueReportUpdateInput!,
        $where: RevenueReportWhereUniqueInput!
    ) {
        updateOneRevenueReport(
            data: $data,
            where: $where
        ) {
            id
        }
    }
`;

export const deleteRevenueReportMutation = gql`
    mutation deleteRevenueReport($where: RevenueReportWhereUniqueInput!) {
        deleteOneRevenueReport(where: $where) {
            id
        }
    }
`;

export const exportRevenueReportsMutation = gql`
    mutation exportRevenueReports($reportsIds: [Int]!) {
        exportRevenueReport(reportIds: $reportsIds)
    }
`;

export const exportFilteredRevenueReportsMutation = gql`
    mutation exportFilteredRevenueReports($where: RevenueReportWhereInput) {
        exportFilteredReportData(where: $where)
    }
`;
