import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/material";
import { FileLink, UploadInput } from "atoms";
import { FormContainer } from "components";
import {
    actionsData,
    fileData,
    identifiersData,
    importRevenueReportTemplateFormData,
    inputData,
} from "data";
import { uploadFileMutations } from "graph";
import { useUploadFile } from "hooks";
import { PropTypes } from "prop-types";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useImportRevenueReportTemplateSchema } from "schemas";
import { StyledTextField } from "styles";
import { deleteFileHandler, renderFormControllerHandler, setUploadInputPreviousValueHandler } from "utils";

const ImportRevenueReportTemplateForm = ({
    refetch,
    setModalData,
}) => {
    const { t } = useTranslation();

    const { id: clientId } = useParams();

    const schema = useImportRevenueReportTemplateSchema();

    const {
        filesData,
        filesLoading,
        setFilesData,
        uploadFile,
    } = useUploadFile();

    const formMethods = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const {
        control,
        formState: { errors },
        setError,
        setValue,
        watch,
    } = formMethods;

    const renderFormControllerChildrenHandler = (
        field,
        label,
        name,
        type,
    ) => {
        if (type === inputData.types.text) {
            return (
                <StyledTextField
                    {...field}
                    error={errors[name]}
                    helperText={errors[name] && errors[name]?.message}
                    id={`${name}_${identifiersData.ids.input}`}
                    label={t(`labels.${label}`)}
                    variant="outlined"
                    fullWidth
                />
            );
        }

        return (
            <Box mb={1}>
                <UploadInput
                    accept={fileData.acceptedExtensions.csv}
                    color={errors[name]?.message ? "error" : "primary"}
                    error={errors[name]?.message}
                    field={field}
                    hasError={!!errors[name]?.message}
                    label={t(`labels.${label}`)}
                    loading={filesLoading?.[name]}
                    onChange={(e) => {
                        field.onChange(e.target.value);

                        setFilesData({ [name]: e.target.files[0] });
                    }}
                />
                {filesData?.[name] && (
                    <FileLink
                        name={filesData?.[name]?.name}
                        deleteAction={() => deleteFileHandler(
                            setFilesData,
                            formMethods,
                            name,
                        )}
                        hasDelete
                    />
                )}
            </Box>
        );
    };

    useEffect(
        () => {
            if (Object.entries(filesLoading).length > 0 && !filesLoading?.revenueReportTemplateFile) refetch();
        },
        [filesLoading], // eslint-disable-line
    );

    useEffect(
        () => {
            setUploadInputPreviousValueHandler(
                watch,
                setError,
                setValue,
                filesData,
                fileData.names.revenueReportTemplate,
            );
        },
        [watch(fileData.names.revenueReportTemplate)], // eslint-disable-line
    );

    return (
        <FormContainer
            action={actionsData["import"]}
            formMethods={formMethods}
            loading={filesLoading?.revenueReportTemplateFile}
            setModalData={setModalData}
            data={renderFormControllerHandler(
                control,
                importRevenueReportTemplateFormData,
                renderFormControllerChildrenHandler,
            )}
            hasCancelButton
            withoutShadow
            onSubmitForm={() => {
                uploadFile(
                    filesData?.revenueReportTemplateFile,
                    uploadFileMutations.revenueReportTemplate,
                    formMethods,
                    fileData.names.revenueReportTemplate,
                    parseInt(clientId),
                    {
                        setModalData,
                        templateData: watch(inputData.names.templateName),
                    },
                );
            }}
        />
    );
};

export default ImportRevenueReportTemplateForm;

ImportRevenueReportTemplateForm.propTypes = {
    refetch: PropTypes.func,
    setModalData: PropTypes.func,
};
