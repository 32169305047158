import { fileData } from "data";
import { uploadFileMutations } from "graph";

export const createNonExistingTrackHandler = async (importerData) => {
    const {
        artistId,
        createTrack,
        existingSecondaryArtists,
        nonExistingSecondaryArtists,
        release,
        track,
    } = importerData;

    if (window?.__RUNTIME_CONFIG__?.ENABLE_DEBUG_LOGS === "true") { // eslint-disable-line
        console.log(
            "releaseDataFromCreateNonExistingTrackHandler",
            release,
        );
    }

    const {
        composers,
        customId,
        fileId,
        isrc,
        iswc,
        otherIsrc,
        secondaryArtist,
        trackNum,
        trackTitleAr,
        trackTitleEn,
        writers,
    } = track;

    const trackSecondaryArtist = existingSecondaryArtists?.find(({ code }) => code === secondaryArtist?.code) || nonExistingSecondaryArtists?.find(({ code }) => code === secondaryArtist?.code);

    await createTrack(
        {
            composers,
            customId: customId?.toString(),
            isrc,
            iswc,
            nameAr: trackTitleAr,
            nameEn: trackTitleEn,
            number: trackNum,
            otherIsrc,
            primaryArtist: artistId,
            secondaryArtists: trackSecondaryArtist ? [{ value: trackSecondaryArtist?.id }] : [],
            trackFile: fileId,
            writers,
        },
        release.id,
        { ...importerData },
    );
};

export const uploadNonExistingTrackFileHandler = async (importerData) => {
    const {
        formMethods,
        track,
        uploadFile,
    } = importerData;

    await uploadFile(
        track?.file,
        uploadFileMutations.track,
        formMethods,
        fileData.names.track,
        null,
        {
            importerData: {
                ...importerData,
                uploadingTrackFile: true,
            },
        },
    );
};

export const addExistingTrackHandler = async (importerData) => {
    const {
        addReleaseTrack,
        release,
        track,
    } = importerData;

    if (window?.__RUNTIME_CONFIG__?.ENABLE_DEBUG_LOGS === "true") { // eslint-disable-line
        console.log(
            "releaseDataFromAddExistingTrackHandler",
            release,
        );
    }

    await addReleaseTrack(
        track?.id,
        release.id,
        {
            ...importerData,
            track,
        },
    );
};

export const getExistingAndNonExistingTracksHandler = (importerData, lists) => {
    const {
        setExistingTracks,
        setNonExistingTracks,
        setStatus,
        tracks,
    } = importerData;

    const { tracks: tracksSearchList } = lists;

    if (tracksSearchList?.length > 0) {
        const searchTracksIsrcs = tracksSearchList?.map(({ isrc }) => isrc);

        const releaseTracksIsrcs = tracks?.map(({ isrc }) => isrc);

        const filteredExistingTracks = tracksSearchList?.filter(({ isrc }) => releaseTracksIsrcs.includes(isrc));

        const filteredNonExistingTracks = tracks?.filter(({ isrc }) => !searchTracksIsrcs.includes(isrc));

        setExistingTracks(filteredExistingTracks);

        setNonExistingTracks(filteredNonExistingTracks);

        setStatus((prev) => ({
            ...prev,
            tracks: {
                ...prev.tracks,
                searching: false,
            },
        }));
    } else setNonExistingTracks(tracks);
};

export const getTrackFileHandler = (isrc, files) => files?.filter((file) => file?.name?.includes(isrc) && fileData.extensions.audio.some((item) => file.name.toLowerCase().includes(item)))[0];
