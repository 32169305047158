import { useAuth0 } from "@auth0/auth0-react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid, Typography } from "@mui/material";
import {
    AutoCompleteMenu,
    FileLink,
    SkeletonLoader,
    UploadInput,
} from "atoms";
import { FormContainer } from "components";
import {
    fileData,
    identifiersData,
    inputData,
    permissionsData,
    releaseVideoFormData,
} from "data";
import { uploadFileMutations } from "graph";
import { useDeleteVideo, useUploadFile } from "hooks";
import { PropTypes } from "prop-types";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useReleaseVideoSchema } from "schemas";
import { canDo, renderFormControllerHandler, setUploadInputPreviousValueHandler } from "utils";

const ReleaseVideoForm = ({
    setTrackId,
    trackId,
    trackLoading,
    values,
}) => {
    const { t } = useTranslation();

    const { user } = useAuth0();

    const schema = useReleaseVideoSchema();

    const tracksList = useSelector((state) => state.releasesReducer)?.releaseTracks;

    const selections = useSelector((state) => state.selectionsReducer);

    const { id: releaseId } = useParams();

    const { deleteVideo } = useDeleteVideo(trackId);

    const {
        files,
        filesData,
        filesLoading,
        uploadFile,
    } = useUploadFile(
        trackId,
        parseInt(releaseId),
    );

    const formMethods = useForm({
        defaultValues: values,
        mode: "onChange",
        resolver: yupResolver(schema),

    });

    const {
        control,
        formState: { errors },
        setError,
        setValue,
        watch,
    } = formMethods;

    const {
        mediaCreate: mediaCreatePermission,
        mediaDelete: mediaDeletePermission,
        mediaRead: mediaReadPermission,
    } = permissionsData.permissions.keys;

    const allowedPermissions = [mediaCreatePermission, mediaReadPermission];

    const {
        file: fileInputName,
        track: trackInputName,
        type: typeInputName,
    } = inputData.names;

    const tracksOptions = tracksList?.map(({
        id,
        title,
    }) => ({
        label: title,
        value: id,
    }));

    const renderFormControllerChildrenHandler = (
        field,
        label,
        name,
        type,
        _,
        selectionKey,
    ) => {
        if (type === inputData.types.file) {
            return (
                <Box mb={1}>
                    <UploadInput
                        accept={fileData.acceptedExtensions.video}
                        color={errors[name]?.message ? "error" : "primary"}
                        error={errors[name]?.message}
                        field={field}
                        hasError={!!errors[name]?.message}
                        label={files?.find(({ field: fileField }) => fileField === name) ? files?.find(({ field: fileField }) => fileField === name)?.value : t(`labels.${label}`)}
                        loading={filesLoading?.[name]}
                        disabled={!watch(typeInputName) || !canDo(
                            user,
                            allowedPermissions,
                        )}
                        onChange={(e) => {
                            uploadFile(
                                e.target.files[0],
                                uploadFileMutations.video,
                                formMethods,
                                name,
                                null,
                                { videoType: watch(typeInputName) },
                            );
                        }}
                    />
                </Box>
            );
        }

        return (
            <AutoCompleteMenu
                errors={errors}
                getRenderedOption={(option) => option.label}
                label={label}
                name={name}
                optionLabel={(option) => option.label}
                options={selectionKey === "tracks" ? tracksOptions : selections?.[selectionKey]}
                onChange={(__, option) => {
                    if (option) field.onChange(option.value);
                    else field.onChange("");
                }}
            />
        );
    };

    useEffect(
        () => {
            setTrackId(tracksList?.find(({ id }) => id === watch(trackInputName))?.id);
        },
        [watch(trackInputName)], // eslint-disable-line
    );

    useEffect(
        () => {
            setUploadInputPreviousValueHandler(
                watch,
                setError,
                setValue,
                filesData,
                fileData.names.video,
            );
        },
        [watch(fileData.names.video)], // eslint-disable-line
    );

    return (
        <FormContainer
            disabled={filesLoading?.length > 0}
            formMethods={formMethods}
            hasSubmitButton={false}
            data={renderFormControllerHandler(
                control,
                releaseVideoFormData,
                renderFormControllerChildrenHandler,
            )}
            otherData={(
                <Grid
                    spacing={2}
                    container
                >
                    <Grid
                        md={8}
                        xs={12}
                        item
                    >
                        {trackLoading ? <SkeletonLoader /> : values?.videos?.length > 0 && values?.videos?.filter(({ release }) => release?.id === parseInt(releaseId)).map(({
                            downloadUri,
                            id,
                            name: fileName,
                            type: fileType,
                        }, index) => (
                            <FileLink
                                key={id}
                                link={downloadUri}
                                name={fileName}
                                otherData={<Typography variant="caption">{fileType?.name}</Typography>}
                                rowId={index + 1}
                                deleteAction={() => {
                                    document.getElementById(`${fileInputName}_${identifiersData.ids.input}`).value = ""; //eslint-disable-line

                                    deleteVideo(id);
                                }}
                                hasDelete={canDo(
                                    user,
                                    [mediaDeletePermission],
                                )}
                                hasDownload
                            />
                        ))}
                    </Grid>
                </Grid>
            )}
        />
    );
};

export default ReleaseVideoForm;

ReleaseVideoForm.propTypes = {
    setTrackId: PropTypes.func,
    trackId: PropTypes.number,
    trackLoading: PropTypes.bool,
    values: PropTypes.object,
};
