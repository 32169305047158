import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";
import { LoadingButton } from "@mui/lab";
import {
    Box,
    Button,
    IconButton,
    TableRow,
    Tooltip,
    Typography,
} from "@mui/material";
import {
    Loader,
    Progressbar,
    Stepper,
    TableList,
} from "atoms";
import { PageHead } from "components";
import {
    identifiersData, inputData, namesData, tableHeadCellsData,
} from "data";
import { PropTypes } from "prop-types";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { StyledTableCell } from "styles";
import { disableImportReleasesButtonHandler, getReleaseStatusHandler } from "utils";

const ImporterReleasesTab = ({
    activeStep,
    currentRelease,
    importing,
    releases,
    releasesLoading,
    setFiles,
    setImporting,
    stopImportAllReleasesCallHandler,
    stopImportOneReleaseCallHandler,
    xlsxFilesDetails,
}) => {
    const { t } = useTranslation();

    const { importer } = useSelector((state) => state.releasesReducer);

    const directoryRef = useRef(null);

    const {
        button: buttonId,
        input: inputId,
    } = identifiersData.ids;

    const {
        startImport: startImportButtonName,
        stop: stopButtonName,
        stopAll: stopAllButtonName,
        uploadReleases: uploadReleasesButtonName,
    } = namesData.buttons;

    const { file: fileInputName } = inputData.names;

    const disableImportReleasesButtonCallHandler = () => disableImportReleasesButtonHandler({
        importer,
        releases,
        xlsxFilesDetails,
    });

    const getReleaseStatusCallHandler = (upc) => getReleaseStatusHandler(
        {
            currentRelease,
            importer,
            upc,
        },
        { translation: t },
    );

    useEffect(
        () => {
            if ((importer?.invalidReleases?.length + importer?.completedReleases?.length) === releases?.length) setImporting(false); //eslint-disable-line
        },
        [importer?.invalidReleases, importer?.completedReleases], //eslint-disable-line
    );

    useEffect(
        () => {
            if (directoryRef.current !== null) {
                directoryRef.current.setAttribute(
                    "directory",
                    "",
                );
                directoryRef.current.setAttribute(
                    "webkitdirectory",
                    "",
                );
            }
        },
        [directoryRef], //eslint-disable-line
    );

    return (
        <Box
            alignItems="center"
            display="flex"
            flexDirection="column"
            justifyContent="center"
        >
            <PageHead
                title={t("releases.name")}
                actionComponent={(
                    <Button
                        disabled={importing}
                        id={`${uploadReleasesButtonName}_${buttonId}`}
                        variant="outlined"
                        onClick={() => {
                            document.getElementById(`${fileInputName}_${inputId}`).value = ""; //eslint-disable-line

                            document.getElementById(`${fileInputName}_${inputId}`).click(); //eslint-disable-line
                        }}
                    >
                        <AddOutlinedIcon />
                        <Typography
                            fontWeight="bold"
                            variant="caption"
                        >
                            {t("actions.uploadReleases")}
                        </Typography>
                        <input
                            id={`${fileInputName}_${inputId}`}
                            ref={directoryRef}
                            style={{ display: "none" }}
                            type={inputData.types.file}
                            onChange={(e) => setFiles([...e.target.files]?.filter((file) => file?.name?.charAt(0) !== "."))}
                        />
                    </Button>
                )}
                hasAction
            />
            <TableList
                headCells={tableHeadCellsData.releases.importer.releasesTab}
                isPaginated={false}
                hasLimitedHeight
            >
                {releases?.map(({
                    albumNameAr,
                    albumNameEn,
                    artist,
                    code,
                    releaseDate,
                    releaseType,
                    upc,
                }, index) => (
                    <TableRow
                        key={code}
                        role="checkbox"
                        tabIndex={-1}
                        aria-checked
                        hover
                    >
                        <StyledTableCell boldCell={+true}>{albumNameEn}</StyledTableCell>
                        <StyledTableCell>{albumNameAr}</StyledTableCell>
                        <StyledTableCell>{releaseType}</StyledTableCell>
                        <StyledTableCell>{upc}</StyledTableCell>
                        <StyledTableCell>{artist?.artistNameEn}</StyledTableCell>
                        <StyledTableCell>{releaseDate}</StyledTableCell>
                        <StyledTableCell align="center">
                            {getReleaseStatusCallHandler(upc) || (currentRelease?.upc?.toString() === upc?.toString() && importing && (
                                <Box
                                    alignItems="center"
                                    display="flex"
                                    gap={2}
                                    justifyContent="center"
                                >
                                    <Loader
                                        size={20}
                                        withoutFullHeight
                                    />
                                    {window?.__RUNTIME_CONFIG__?.ENABLE_RELEASES_IMPORTER_STOP_AND_RETRY !== "false" && (//eslint-disable-line
                                        <Box>
                                            {importer?.stopImporting?.start && !importer?.stopImporting?.finish ? <Typography>{t("labels.stopping")}</Typography> : (
                                                <Tooltip title={t("actions.stop")}>
                                                    <IconButton
                                                        id={`${stopButtonName}_${index + 1}_${buttonId}`}
                                                        onClick={stopImportOneReleaseCallHandler}
                                                    >
                                                        <PauseCircleOutlineIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                        </Box>
                                    )}
                                </Box>
                            )) }
                        </StyledTableCell>
                    </TableRow>
                ))}
            </TableList>
            <Box
                alignItems="center"
                display="flex"
                gap={2}
                justifyContent="center"
            >
                <LoadingButton
                    disabled={disableImportReleasesButtonCallHandler()}
                    id={`${startImportButtonName}_${buttonId}`}
                    loading={ releasesLoading || importing } //eslint-disable-line
                    sx={{ marginBlock: 2 }}
                    variant="contained"
                    onClick={() => setImporting(true)}
                >
                    {t("actions.startImport")}
                </LoadingButton>
                {importing && !releasesLoading && window?.__RUNTIME_CONFIG__?.ENABLE_RELEASES_IMPORTER_STOP_AND_RETRY !== "false" &&(  //eslint-disable-line
                    <Button
                        color="error"
                        id={`${stopAllButtonName}_${buttonId}`}
                        variant="outlined"
                        onClick={stopImportAllReleasesCallHandler}
                    >
                        {t("actions.stopAll")}
                    </Button>
                )}
            </Box>
            <Box
                mb={3}
                width="50%"
            >
                <Progressbar
                    progress={releases.length === 0 ? 0 : ((importer?.invalidReleases?.length + importer?.completedReleases?.length) / releases.length) * 100} // eslint-disable-line
                    text={t(
                        "labels.importerProgressbarDescription",
                        {
                            importedReleases: importer?.invalidReleases?.length + importer?.completedReleases?.length, // eslint-disable-line
                            totalReleases: releases.length,
                        },
                    )}
                />
            </Box>
            <Stepper
                activeStep={activeStep}
                steps={[
                    "createArtist",
                    "uploadReleaseImage",
                    "createRelease",
                    "uploadTracks",
                    "uploadVideos",
                    "uploadDocuments",
                ]}
            />
        </Box>
    );
};

export default ImporterReleasesTab;

ImporterReleasesTab.propTypes = {
    activeStep: PropTypes.number,
    currentRelease: PropTypes.object,
    importing: PropTypes.bool,
    releases: PropTypes.array,
    releasesLoading: PropTypes.bool,
    setFiles: PropTypes.func,
    setImporting: PropTypes.func,
    stopImportAllReleasesCallHandler: PropTypes.func,
    stopImportOneReleaseCallHandler: PropTypes.func,
    xlsxFilesDetails: PropTypes.array,
};
