import { constantsData, fileData } from "data";
import { uploadFileMutations } from "graph";
import { removeInvalidImporterRelease, setInvalidImporterRelease, stopReleaseImporting } from "redux/actions";
import { StyledChip } from "styles";
import { reverseDateHandler } from "utils";

export const createReleaseNoteHandler = async (importerData) => {
    const {
        createReleaseNote,
        release,
    } = importerData;

    if (window?.__RUNTIME_CONFIG__?.ENABLE_DEBUG_LOGS === "true") { // eslint-disable-line
        console.log(
            "releaseDataFromCreateReleaseNoteHandler",
            release,
        );
    }

    const {
        id,
        note,
    } = release;

    await createReleaseNote(
        { note },
        id,
        { ...importerData },
    );
};

export const createReleaseOwnershipHandler = async (importerData) => {
    const {
        createReleaseOwnership,
        release,
    } = importerData;

    if (window?.__RUNTIME_CONFIG__?.ENABLE_DEBUG_LOGS === "true") { // eslint-disable-line
        console.log(
            "releaseDataFromCreateReleaseOwnershipHandler",
            release,
        );
    }

    const {
        cLine,
        label,
        pLine,
        territory,
    } = release?.ownership || {};

    await createReleaseOwnership(
        {
            cLine,
            label,
            pLine,
            territory: territory?.value,
        },
        release.id,
        { ...importerData },
    );
};

export const createReleaseHandler = async (importerData) => {
    const {
        artistId,
        createRelease,
        imageId,
        release,
    } = importerData;

    const {
        albumNameAr,
        albumNameEn,
        code,
        discNum,
        endDate,
        genre,
        image,
        releaseDate,
        releaseType,
        rightType,
        upc,
    } = release;

    await createRelease(
        {
            albumCode: code,
            nameAr: albumNameAr,
            nameEn: albumNameEn,
            primaryArtist: artistId,
            releaseDate: new Date(reverseDateHandler(releaseDate)),
            upc,
            ...endDate && { endDate: new Date(reverseDateHandler(endDate)) },
            ...image && { imageFile: imageId },
            ...discNum && { discNumber: discNum },
            genre: genre?.value,
            releaseType: releaseType?.value,
            rightType: rightType?.value,
        },
        { ...importerData },
    );
};

export const uploadReleaseImageHandler = async (importerData) => {
    const {
        formMethods,
        release,
        uploadFile,
    } = importerData;

    await uploadFile(
        release?.image,
        uploadFileMutations.image,
        formMethods,
        fileData.names.image,
        null,
        {
            importerData: {
                ...importerData,
                uploadingReleaseImage: true,
            },
        },
    );
};

export const getReleaseImageHandler = (upc, files) => files?.filter((file) => file?.name?.includes(upc) && fileData.extensions.image.some((item) => file.name.toLowerCase().includes(item)))[0];

export const retryInvalidReleaseHandler = (states, data, utils) => {
    const {
        setCurrentRelease,
        setImporting,
        setIsOneReleaseRetry,
        setStatus,
    } = states;

    const {
        initialState,
        invalidRelease,
    } = data;

    const { dispatch } = utils;

    setCurrentRelease(invalidRelease);

    setImporting(true);

    setIsOneReleaseRetry(true);

    setStatus(initialState);

    dispatch(stopReleaseImporting({
        finish: false,
        start: false,
    }));

    dispatch(removeInvalidImporterRelease(invalidRelease.upc));
};

export const retryAllInvalidReleasesHandler = (states, data, utils) => {
    const {
        initialState,
        invalidReleases,
        releases,
    } = data;

    const {
        setCurrentRelease,
        setImporting,
        setIsOneReleaseRetry,
        setStatus,
    } = states;

    const { dispatch } = utils;

    invalidReleases.forEach((element) => dispatch(removeInvalidImporterRelease(element?.release?.data?.upc)));

    const firstRelease = releases.find((release) => invalidReleases.find((invalidRelease) => invalidRelease?.release?.data?.upc === release?.upc));

    setIsOneReleaseRetry(false);

    setStatus(initialState);

    setCurrentRelease(firstRelease);

    dispatch(stopReleaseImporting({
        finish: false,
        start: false,
    }));

    setImporting(true);
};

export const stopImportOneReleaseHandler = (data, utils) => {
    const { importer } = data;

    const { dispatch } = utils;

    dispatch(stopReleaseImporting({
        ...importer.stopImporting,
        start: true,
    }));
};

export const stopImportAllReleasesHandler = (states, data, utils) => {
    const {
        currentRelease,
        importer,
        initialState,
        releases,
    } = data;

    const {
        setCurrentRelease,
        setStatus,
    } = states;

    const {
        dispatch,
        translation,
    } = utils;

    dispatch(stopReleaseImporting({
        ...importer.stopImporting,
        start: true,
    }));

    setStatus(initialState);

    setCurrentRelease(null);

    const currentReleaseIndex = releases.findIndex((release) => release.upc?.toString() === currentRelease.upc?.toString());

    if (currentReleaseIndex !== releases.length - 1) {
        for (let index = currentReleaseIndex; index < releases.length; index += 1) {
            const element = releases[index];

            if (!importer?.invalidReleases?.find(({ release }) => release?.data.upc === element?.upc) && !importer?.completedReleases?.find((release) => release?.upc === element?.upc)) {
                dispatch(setInvalidImporterRelease(
                    {
                        isStopped: true,
                        originalData: { release: element },
                        release: {
                            data: element,
                            message: translation("messages.importerMsgs.stoppedRelease"),
                        },
                    },
                ));
            }
        }
    }
};

export const getNextReleaseHandler = (states, data, utils) => {
    const {
        setCurrentRelease,
        setIsReleaseAlreadyExists,
        setStatus,
        setValidation,
    } = states;

    const { dispatch } = utils;

    const {
        currentRelease,
        importer,
        importing,
        initialState,
        isOneReleaseRetry,
        isReleaseAlreadyExists,
        releases,
        status,
        validation,
    } = data;

    const statusObjects = Object.values(status);

    if (currentRelease) {
        if (
            (
                statusObjects?.filter((object) => object.success === false).length > 0
                || statusObjects?.filter((object) => object.success === true).length === statusObjects?.length
                || isReleaseAlreadyExists
                || importer.stopImporting.finish
                || validation === false
            ) && !isOneReleaseRetry
        ) {
            setValidation(null);

            setIsReleaseAlreadyExists(false);

            if (importer.stopImporting.finish || importer.stopImporting.start) {
                dispatch(stopReleaseImporting({
                    finish: false,
                    start: false,
                }));
            }

            const currentReleaseIndex = releases.findIndex((release) => release.upc?.toString() === currentRelease.upc?.toString());

            if (currentReleaseIndex !== releases.length - 1 && importing) {
                setStatus(initialState);

                if (importer.completedReleases.find((release) => release?.upc === releases[currentReleaseIndex + 1]?.upc) || importer.invalidReleases.find(({ release }) => release?.data?.upc === releases[currentReleaseIndex + 1]?.upc)) setCurrentRelease(releases[currentReleaseIndex + 2]);
                else setCurrentRelease(releases[currentReleaseIndex + 1]);
            }
        }
    } else {
        setStatus(initialState);

        setCurrentRelease(releases[0]);
    }
};

export const getReleaseStatusHandler = (data, utils) => {
    const {
        currentRelease,
        importer,
        upc,
    } = data;

    const { translation } = utils;

    const invalidRelease = importer.invalidReleases.find(({ release }) => release?.data?.upc === upc);

    const completedRelease = importer.completedReleases.find((release) => release?.upc === upc);

    switch (true) {
    case !upc && currentRelease:
    case !!invalidRelease:
        if (invalidRelease?.isStopped) {
            return (
                <StyledChip
                    label={translation("labels.stopped")}
                    status="stopped"
                />
            );
        }

        return (
            <StyledChip
                label={translation("labels.failed")}
                status="failed"
            />
        );

    case !!completedRelease:
        return (
            <StyledChip
                label={translation("labels.completed")}
                status="completed"
            />
        );

    default:
        return "";
    }
};

export const disableImportReleasesButtonHandler = (data) => {
    const {
        importer,
        releases,
        xlsxFilesDetails,
    } = data;

    if (xlsxFilesDetails?.length === 0 || (importer.invalidReleases?.length + importer?.completedReleases?.length) === releases?.length) return true; //eslint-disable-line

    return false;
};

export const getReleaseFileErrorMessageHandler = (error, translationKey, translation) => {
    let message = "";

    if (error.message === constantsData.canceled) message = translation("messages.importerMsgs.stoppedRelease");
    else if (error.message === constantsData.networkError) message = translation("messages.networkFailure");
    else message = `${translation(`messages.importerMsgs.${translationKey}`)}, ${error.message}`;

    return message;
};
