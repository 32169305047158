import { fileData } from "data";
import { uploadFileMutations } from "graph";

export const createDocumentHandler = async (importerData) => {
    const {
        createReleaseDocument,
        document,
        release,
        trackId,
    } = importerData;

    if (window?.__RUNTIME_CONFIG__?.ENABLE_DEBUG_LOGS === "true") { // eslint-disable-line
        console.log(
            "releaseDataFromCreateDocumentHandler",
            release,
        );
    }

    const {
        fileId,
        type,
    } = document;

    await createReleaseDocument(
        {
            ...type && { type: type?.value },
            documentFile: fileId,
            tracks: trackId ? [{ id: trackId }] : [],
        },
        release.id,
        { ...importerData },
    );
};

export const uploadDocumentFileHandler = async (importerData) => {
    const {
        document,
        formMethods,
        uploadFile,
    } = importerData;

    await uploadFile(
        document?.file,
        uploadFileMutations.document,
        formMethods,
        fileData.names.document,
        null,
        {
            importerData: {
                ...importerData,
                uploadingDocumentFile: true,
            },
        },
    );
};

export const getDocumentsHandler = (upc, isrc, files, setDocuments) => {
    let releaseDocuments = [];

    const trackDocumentsFilteredArray = isrc ? files?.filter((file) => file?.name?.includes(isrc) && fileData.extensions.document.some((item) => item !== "xls" && file.name.toLowerCase().includes(item))) : [];

    const releaseDocumentsFilteredArray = !isrc ? files?.filter((file) => file?.name?.includes(upc) && fileData.extensions.document.some((item) => item !== "xls" && file.name.toLowerCase().includes(item))) : [];

    if (isrc && trackDocumentsFilteredArray?.length > 0) releaseDocuments = releaseDocuments.concat(trackDocumentsFilteredArray);
    else if (!isrc && releaseDocumentsFilteredArray?.length > 0) releaseDocuments = releaseDocuments.concat(releaseDocumentsFilteredArray);

    if (releaseDocuments?.length > 0) {
        releaseDocuments.forEach((document) => {
            const typeIndex = document?.name?.indexOf("_");
            const documentType = typeIndex > 0 ? document?.name?.split(".").slice(
                0,
                -1,
            ).join(".").substring(typeIndex + 1) : undefined;

            setDocuments((prev) => {
                const newDocument = {
                    file: document,
                    ...documentType && { type: documentType },
                    upc,
                    ...isrc && {
                        isrc,
                    },
                };

                return {
                    ...prev,
                    [upc]: prev?.[upc]?.length > 0 ? prev?.[upc].concat([newDocument]) : [].concat([newDocument]),
                };
            });
        });
    }
};
