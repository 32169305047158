const identifiersData = {
    classNames: {
        active: "active",
        categoryFilterBox: "category-filter-box",
        categoryFilterSelectMenu: "category-filter-select-menu",
        categoryFilterSelectMenuFormControl: "category-filter-select-menu-form-control",
        categoryFilterTagsBox: "category-filter-tags-box",
        categoryFilterTextField: "category-filter-text-field",
        clearIndicator: "MuiAutocomplete-clearIndicator",
        controls: "controls",
        filterRangeInput: "filter-range-input",
        searchBox: "search-box",
        searchButton: "search-button",
        searchSelectMenu: "search-select-menu",
        searchSelectMenuFormControl: "search-select-menu-form-control",
        searchTagsBox: "search-tags-box",
        searchTextField: "search-text-field",
        sliderRoot: "MuiSlider-root",
        sliderThumb: "MuiSlider-thumb",
        sliderTrack: "MuiSlider-track",
        smallField: "small-field",
        switchBase: "MuiSwitch-switchBase",
        thumbnail: "thumbnail",
        upload: "upload",
        videoTopBox: "video-top-box",
        withoutPosition: "without-position",
    },
    ids: {
        accordion: "ACCORDION",
        alert: "ALERT",
        avatar: "AVATAR",
        button: "BUTTON",
        card: "CARD",
        filters: "FILTERS",
        foldersStructure: "FOLDERS_STRUCTURE",
        input: "INPUT",
        label: "LABEL",
        link: "LINK",
        list: "LIST",
        menu: "MENU",
        message: "MESSAGE",
        modal: "MODAL",
        option: "OPTION",
        selectMenu: "SELECT_MENU",
        sidebar: "SIDEBAR",
        slider: "SLIDER",
        tab: "TAB",
        table: "TABLE",
        thumb: "THUMB",
        thumbnail: "THUMBNAIL",
        toast: "TOAST",
        toolbar: "TOOLBAR",
        track: "TRACK",
        treeItem: "TREE_ITEM",
    },
    props: {
        backgroundImg: "backgroundImg",
        boldCell: "boldCell",
        fromNavbar: "fromNavbar",
        fullWidth: "fullWidth",
        hasJustifyStart: "hasJustifyStart",
        hasLargeSelectMenu: "hasLargeSelectMenu",
        hasSelectMode: "hasSelectMode",
        isCalendar: "isCalendar",
        open: "open",
        rowHeight: "rowHeight",
        showControls: "showControls",
        valuesLength: "valuesLength",
        withoutShadow: "withoutShadow",
        withoutStyle: "withoutStyle",
    },
};

export default identifiersData;
