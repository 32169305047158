import AudiotrackIcon from "@mui/icons-material/Audiotrack";
import CloseIcon from "@mui/icons-material/Close";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import {
    Avatar,
    Box,
    Fade,
    IconButton,
    Slider,
    Tooltip,
    Typography,
} from "@mui/material";
import { SkeletonLoader } from "atoms";
import { identifiersData, namesData } from "data";
import { PropTypes } from "prop-types";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentPlayedTrack } from "redux/actions";
import { StyledAudioPlayer } from "styles";
import { theme } from "theme";
import { convertHourToDurationFormatHandler, getSubstringHandler } from "utils";

const AudioPlayer = ({ open }) => {
    const [isPlaying, setIsPlaying] = useState(false);

    const [currentTime, setCurrentTime] = useState(0);

    const [duration, setDuration] = useState(0);

    const [volume, setVolume] = useState(1);

    const [loading, setLoading] = useState(true);

    const dispatch = useDispatch();

    const track = useSelector((state) => state.tracksReducer).playedTrack;

    const { t } = useTranslation();

    const audioRef = useRef();

    const {
        classNames: { controls: controlsClassName },
        ids: {
            button: buttonId,
            slider: sliderId,
            thumb: thumbId,
            track: trackId,
        },
    } = identifiersData;

    const {
        buttons: {
            closeAudioPlayer: closeAudioPlayerButtonName,
            downloadTrack: downloadTrackButtonName,
        },
        sliders: {
            audioPlayerDuration: audioPlayerDurationSliderName,
            audioPlayerVolume: audioPlayerVolumeSliderName,
        },
    } = namesData;

    const playAndPauseHandler = () => {
        if (audioRef?.current?.paused) {
            audioRef?.current?.play();

            setIsPlaying(true);
        } else {
            audioRef?.current?.pause();

            setIsPlaying(false);
        }
    };

    const changeTimeHandler = (time) => {
        if (time) {
            const audio = audioRef.current;

            audio.currentTime = time;

            audioRef?.current?.play();

            setCurrentTime(time);
        } else setCurrentTime(0);
    };

    const changeVolumeHandler = (event) => {
        const volumeValue = event.target.value;

        setVolume(volumeValue);

        audioRef.current.volume = volumeValue;
    };

    useEffect(
        () => {
            if (track?.downloadUri) {
                const loadedMetadataHandler = () => { if (audioRef?.current?.duration) setDuration(audioRef?.current?.duration); else setDuration(0); };

                const updateTimeHandler = () => { if (audioRef?.current?.currentTime) setCurrentTime(audioRef?.current?.currentTime); else setCurrentTime(0); };

                audioRef?.current?.addEventListener(
                    "loadedmetadata",
                    loadedMetadataHandler,
                );

                audioRef?.current?.addEventListener(
                    "timeupdate",
                    updateTimeHandler,
                );

                return () => {
                    audioRef?.current?.removeEventListener(
                        "loadedmetadata",
                        loadedMetadataHandler,
                    );

                    audioRef?.current?.removeEventListener( //eslint-disable-line
                        "timeupdate",
                        updateTimeHandler,
                    );
                };
            }
        },
        [track?.downloadUri],
    );

    return (
        <StyledAudioPlayer open={open}>
            {track?.downloadUri && (
                <audio
                    ref={audioRef}
                    src={`${window?.__RUNTIME_CONFIG__?.REACT_APP_BACKEND_DOMAIN}${track?.downloadUri}`} // eslint-disable-line
                    autoPlay
                    onEnded={() => setIsPlaying(false)}
                    onCanPlay={() => {
                        setLoading(false);

                        setIsPlaying(true);
                    }}
                />
            )}
            <Fade
                direction="up"
                in={!!track?.downloadUri}
            >
                <Box className={controlsClassName}>
                    <Box>
                        <Tooltip title={t("actions.close")}>
                            <IconButton
                                id={`${closeAudioPlayerButtonName}_${buttonId}`}
                                onClick={() => dispatch(setCurrentPlayedTrack({}))}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Box
                        alignItems="center"
                        display="flex"
                        gap={2}
                        justifyContent="space-between"
                    >
                        {isPlaying ? (
                            <PauseCircleIcon
                                sx={{
                                    color: theme.palette.primary.main,
                                    cursor: "pointer",
                                }}
                                onClick={playAndPauseHandler}
                            />
                        ) : (
                            <PlayCircleFilledIcon
                                sx={{
                                    color: theme.palette.primary.main,
                                    cursor: "pointer",
                                }}
                                onClick={playAndPauseHandler}

                            />
                        )}
                        {loading ? (
                            <SkeletonLoader
                                count={1}
                                style={{
                                    width: {
                                        md: 300,
                                        xs: 200,
                                    },
                                }}
                            />
                        ) : (
                            <Slider
                                max={duration}
                                value={currentTime}
                                slotProps={{
                                    thumb: { id: `${audioPlayerDurationSliderName}_${sliderId}_${thumbId}` },
                                    track: { id: `${audioPlayerDurationSliderName}_${sliderId}_${trackId}` },
                                }}
                                sx={{
                                    width: {
                                        lg: 500,
                                        sm: 250,
                                        xl: 600,
                                        xs: 200,
                                    },
                                }}
                                onChange={(e) => changeTimeHandler(e.target.value)}
                            />
                        )}
                    </Box>
                    <Box
                        fontSize={{
                            md: 18,
                            xs: 15,
                        }}
                    >
                        <time>
                            {convertHourToDurationFormatHandler(currentTime)}
                            /
                            {convertHourToDurationFormatHandler(duration)}
                        </time>
                    </Box>
                    <Box
                        alignItems="center"
                        gap={1}
                        justifyContent="start"
                        display={{
                            md: "flex",
                            xs: "none",
                        }}
                    >
                        <VolumeUpIcon />
                        <Slider
                            max={1}
                            min={0}
                            step={0.1}
                            sx={{ width: 100 }}
                            value={volume}
                            slotProps={{
                                thumb: { id: `${audioPlayerVolumeSliderName}_${sliderId}_${thumbId}` },
                                track: { id: `${audioPlayerVolumeSliderName}_${sliderId}_${trackId}` },
                            }}
                            onChange={changeVolumeHandler}
                        />
                    </Box>
                    <Box
                        gap={1}
                        justifyContent="start"
                        display={{
                            md: "flex",
                            xs: "none",
                        }}
                        minWidth={{
                            lg: 400,
                            xs: 200,
                        }}
                    >
                        <Avatar
                            alt={t("imageAlternatives.track")}
                            imgProps={{ loading: "lazy" }}
                            sx={{ marginTop: 1 }}
                            src={`${track?.image?.downloadThumbnail ? window?.__RUNTIME_CONFIG__?.REACT_APP_BACKEND_DOMAIN + track?.image?.downloadThumbnail : ""}`} // eslint-disable-line
                        >
                            <AudiotrackIcon />
                        </Avatar>
                        <Box
                            display="flex"
                            flexDirection="column"
                        >
                            <Tooltip title={track?.title}>
                                <Typography variant="body1">
                                    {getSubstringHandler(
                                        track?.title,
                                        50,
                                        true,
                                    )}
                                </Typography>
                            </Tooltip>
                            <Typography variant="overline">{track?.artist?.name}</Typography>
                        </Box>
                        <Box
                            alignItems="center"
                            ml="auto"
                        >
                            <Tooltip title={t("tracks.downloadTrack")}>
                                <a href={`${window?.__RUNTIME_CONFIG__?.REACT_APP_BACKEND_DOMAIN}${track?.downloadUri}`} >  {/*eslint-disable-line*/}
                                    <IconButton id={`${downloadTrackButtonName}_${buttonId}`}>
                                        <DownloadOutlinedIcon sx={{ fontSize: 25 }} />
                                    </IconButton>
                                </a>
                            </Tooltip>
                        </Box>
                    </Box>
                </Box>
            </Fade>
        </StyledAudioPlayer>
    );
};

export default AudioPlayer;

AudioPlayer.propTypes = { open: PropTypes.bool };
